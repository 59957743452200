import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Carrousel from "../Detalle/Carrousel";
import { $ } from "react-jquery-plugin";
const Imagen = ({
  imagen,
  guardarDetalle,
  Detalles,
  imagenes2,
  apiUrlState,
}) => {
  console.log(imagen);
  // extraer las variables
  //const { largeImageURL, likes, previewURL, tags, views } = imagen;
  const { titulo, precio, Images, descripcion, ciudad, codigo } = imagen;
  //console.log("Detalle: ",imagen.codigo)
  //console.log("imagen:************ ", imagen)
  const Detallescasa = (Img) => {
    //window.location.href = '/Detalle';
    const prueba = {
      detalle: [{ id: 50, nombre: "Casa 4", precio: 80000, img: Img }],
      cambio: true,
      galeria: Images,
      descripcion: imagen,
    };
    guardarDetalle(prueba);
  };

  const formatNumber = (amount) => {
    var temp = amount + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };

  useEffect(() => {
    /*if(Detalles["cambio"])
        window.location.href = '/Detalle';*/
    $(".carousel").on("touchstart", function (event) {
      const xClick = event.originalEvent.touches[0].pageX;
      $(this).one("touchmove", function (event) {
        const xMove = event.originalEvent.touches[0].pageX;
        const sensitivityInPx = 5;

        if (Math.floor(xClick - xMove) > sensitivityInPx) {
          $(this).carousel("next");
        } else if (Math.floor(xClick - xMove) < -sensitivityInPx) {
          $(this).carousel("prev");
        }
      });
      $(this).on("touchend", function () {
        $(this).off("touchmove");
      });
    });
  });

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      {/* carrousel de prueba */}

      <div className="card">
        <img
          src={apiUrlState + Images[0]}
          className="card-img-top"
          style={{ height: "25vh", objectFit: "cover" }}
        />
        {/*<div id={"CasaNro"+imagen.codigo} className="carousel slide"  data-interval="5000" data-touch="true" data-ride="carousel">
                    <div className="carousel-inner " style={{height: "250px"}}>
                    
                    
                {Images.map((imagen, index) => (
                    //console.log("Map Get Casas: ", imagen)
                    (index > 0) ? 
                        <div className="carousel-item " key={imagen}>
                            <img className="d-block w-100" src={apiUrlState+imagen} style={{ objectFit: "cover", height: "250px", width:"100%"}} loading = "lazy" />
                        </div>
                        :
                        <div className="carousel-item active" key={imagen}>
                            <img className="d-block w-100" src={apiUrlState+Images[0]} style={{ objectFit: "cover", height: "250px",width:"100%"}} loading = "lazy" />
                        </div>
                ))}
                        
                </div>
                <a className="carousel-control-prev" href={"#CasaNro"+imagen.codigo} role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href={"#CasaNro"+imagen.codigo} role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>*/}

        <Link
          to={"/Detalle/" + codigo}
          onClick={() => Detallescasa(apiUrlState + Images[0])}
        >
          <div className="card-body">
            {imagen.vendido ? (
              <p
                className="card-text"
                style={{
                  color: "#ff0000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  textDecorationColor: "none",
                }}
              >
                VENDIDO
              </p>
            ) : (
              <p
                className="card-text"
                style={{
                  color: "#500f0f",
                  fontSize: "20px",
                  fontWeight: "bold",
                  textDecorationColor: "none",
                }}
              >
                $ {formatNumber(precio)}
              </p>
            )}
            <hr
              style={{
                color: "#500f0f",
                backgroundColor: "#500f0f",
                height: 1,
                borderColor: "#500f0f",
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            />
            <p
              className="card-text"
              style={{ color: "#500f0f", fontSize: "15px" }}
            >
              Ciudad: {ciudad}{" "}
            </p>
            <hr
              style={{
                color: "#500f0f",
                backgroundColor: "#500f0f",
                height: 1,
                borderColor: "#500f0f",
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            />
            <p
              className="card-text"
              style={{ color: "#500f0f", fontSize: "15px" }}
            >
              {titulo}{" "}
            </p>
          </div>

          <div className="card-footer">
            <div
              className="btn btn-primary btn-block"
              style={{ backgroundColor: "#4c0000", border: "0" }}
            >
              Ver Detalles
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Imagen;
