import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
//import express from "express";
import AuthContext from "../../context/autenticacion/authContext";
import clienteAxios from "../../config/Axios";
import swal from "sweetalert";

const Ver_Editar = ({
  headers,
  cambiarheader,
  Editar,
  SetEditar,
  apiUrlState,
}) => {
  /*const authContext = useContext(AuthContext);
    const { usuarioAutenticado } = authContext;*/

  useEffect(() => {
    if (headers["display"] === "flex")
      cambiarheader({
        position: "relative",
        color: "#ffff",
        display: "inline",
        Login: "/img/Header-Aldia2.png",
      });

    //usuarioAutenticado();
    GetHouse();
  }, []);
  const [Casas, Getcasas] = useState([]);
  const [Busqueda, GetBusqueda] = useState({ busqueda: "" });
  const [filtroB, GetfiltroB] = useState([]);
  const GetHouse = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/casas");
      //console.log("GET, CASAS", JSON.stringify(respuesta.data[0]))
      Getcasas(respuesta.data);
      GetfiltroB(respuesta.data);
    } catch (error) {
      //console.log("**********")
    }
  };
  /*console.log("Busqueda",Busqueda)
        console.log("filtroB",filtroB)
        console.log("Casas",Casas)*/

  const onChange = async (e) => {
    e.persist();
    //console.log("e.target.value: ", e.target.value)

    //var value = e.target.value

    GetBusqueda({ busqueda: e.target.value });
    filtrarElementos(e.target.value);
  };

  const filtrarElementos = (valor) => {
    var buscar = filtroB;
    //console.log("Busqueda en filtro: ", Busqueda)
    var search = Casas.filter((item) => {
      //console.log("item entra: ", item.codigo.toString() , Busqueda.busqueda)
      if (
        item.codigo.toString().includes(valor) ||
        item.titulo
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(valor) ||
        item.barrio.includes(valor) ||
        Busqueda.busqueda === "" ||
        item.ciudad
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(valor)
      ) {
        return item;
      }
    });
    buscar = search;
    /*console.log("buscar: ", buscar)
            console.log("Busqueda: ", Busqueda)
            console.log("Casas: ", Casas)*/

    GetfiltroB(search);
  };

  /*useEffect(()=>{
            GetHouse()
        },[])*/
  //console.log("Casas",Casas)

  const Eliminar = async (e, id) => {
    swal({
      title: "Esta seguro de eliminar esta casa ?",
      text: "Al eliminarla no se podran recuperar los datos",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //console.log("id",id)
        var ruta = "/api/casas" + id.toString();
        //console.log("ruta", ruta)

        const respuesta = clienteAxios.delete("/api/casas/" + id);
        console.log("DELETE, CASAS", JSON.stringify(respuesta.data));
        GetHouse();

        swal("Poof! Casa Eliminada", {
          icon: "success",
        });
        //window.location.href = '/VerEditar';
      } else {
        swal("La casa no se eliminó");
      }
    });
  };
  const Vender = async (e, id) => {
    swal({
      title: "Marcar venta",
      text: "Esta seguro de marcar como vendida esta casa ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //console.log("id",id)
        var ruta = "/api/casas/Venta/" + id.toString();
        //console.log("ruta", ruta)

        const respuesta = clienteAxios.put("/api/casas/Venta/" + id);
        console.log("VENDER, CASAS", JSON.stringify(respuesta.data));
        GetHouse();

        swal("Poof! Casa Vendida", {
          icon: "success",
        });
        //window.location.href = '/VerEditar';
      } else {
        swal("La casa no se Vendio");
      }
    });
  };

  const editar = async (e, idimg) => {
    //e.preventDefault();

    //SetEditar(id)
    SetEditar({
      ...Editar,
      id: idimg,
    });
  };

  const formatNumber = (amount) => {
    var temp = amount + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };

  return (
    <div
      className="container-fluid scroll-invisible"
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <input
        className="form-control mr-sm-2"
        type="search"
        placeholder="Buscar"
        aria-label="Search"
        style={{ marginTop: "40px" }}
        value={Busqueda.busqueda}
        onChange={(e) => onChange(e)}
      />
      <table
        className="table table-bordered"
        style={{ marginTop: "40px", textAlign: "center" }}
      >
        <thead>
          <tr>
            <th scope="col">Miniatura</th>
            <th scope="col">Titulo</th>
            <th scope="col">Codigo</th>
            <th scope="col">Descripcion</th>
            <th scope="col">Ciudad</th>
            <th scope="col">Precio</th>
            <th colSpan="3">Accion</th>
            <th colSpan="col">Nombre Cli.</th>
            <th colSpan="col">Tel Cli.</th>
            <th colSpan="col">Direcccion</th>
          </tr>
        </thead>
        <tbody>
          {filtroB.map((casa) => (
            <tr key={casa._id}>
              <td scope="row" style={{ textAlign: "center" }}>
                <img
                  style={{ height: "100px", width: "100px" }}
                  src={apiUrlState + casa.Images[0]}
                ></img>
              </td>
              <td>{casa.titulo}</td>
              <td>{casa.codigo}</td>
              <td>{casa.descripcion}</td>
              <td>{casa.ciudad}</td>
              <td>{formatNumber(casa.precio)}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => Eliminar(e, casa._id)}
                >
                  Eliminar
                </button>
              </td>
              <td>
                <div onClick={(e) => editar(e, casa._id)}>
                  <Link to="/Editar">
                    <div type="button" className="btn btn-primary">
                      Editar
                    </div>
                  </Link>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => Vender(e, casa._id)}
                >
                  Vender
                </button>
              </td>
              <td>{casa.nombre}</td>
              <td>{casa.telefono}</td>
              <td>{casa.direccion}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Ver_Editar;
