import React, { useState, useEffect, Fragment, useContext } from "react";
import clienteAxios from "../../config/Axios";
import { useParams } from "react-router";
const Filtro = ({ imagenes2, mensaje, guardarImagenes2 }) => {
  //const data = require('./Colombia.json');
  //const respuesta =  clienteAxios.get('/api/casas/Ciudad');
  const [Filtro, GetFiltro] = useState([]);
  const [Dep, GetDep] = useState([]);
  const [Barrios, GetBarrios] = useState([]);
  const [Peticion, GetPeticion] = useState([
    {
      ciudad: "",
      barrio: "",
      estrato: "",
      precioMax: "",
      precioMin: "",
      habitaciones: "",
      baños: "",
      areafrente: "",
      areafondo: "",
      Tipo: "",
    },
  ]);
  //{"ciudad":"", "barrio": "", "estrato": "", "precio": "", "habitaciones": "", "baños": "", "area.frente" : "", "area.frente.fondo" : ""}
  let { Tipo } = useParams();
  const Filtros = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/casas/Ciudad");

      //console.log("Filtros", JSON.stringify(respuesta.data))
      GetFiltro(respuesta.data);
    } catch (error) {
      //console.log("**********")
    }
  };
  const Filtros_Departamentos = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/casas/Departamento");

      //console.log("Filtros_Departamentos", JSON.stringify(respuesta.data))
      GetDep(respuesta.data);
    } catch (error) {
      //console.log("**********")
    }
  };
  useEffect(() => {
    /*if(Tipo === "Casas" ){//&& imagenes2["Cambio"]
            //buscar({$or: [{"tipo": "Casa"}, {"tipo": "Apartamento"}]})
            const respuesta =  clienteAxios.post('/api/casas/Filtrar', {$or: [{"tipo": "Casa"}, {"tipo": "Apartamento"}]});
            guardarImagenes2({"Cambio": false, "Lista": respuesta.data.casa})
            console.log("Tipo:***** ", Tipo)
            //Tipo = "vacio"
        }
        if(Tipo === "Lotes" ){//&& imagenes2["Cambio"]
            buscar({"tipo": "Lote"})
            
            console.log("Tipo: ", Tipo)
            //Tipo = "vacio"
        }

        
        /*if(Tipo != "Lotes" && Tipo != "Casas"){
            buscar({})
            
            console.log("Tipo: ", Tipo)
            Tipo = ""
        }*/
    Filtros();
    Filtros_Departamentos();
  }, []);

  /*
    const getData=()=>{
        var json = []
        fetch('http://localhost:4000/Colombia.json'
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
           //console.log(response)
            return response.json();
          })
          .then(function(myJson) {
           //console.log("JSON",myJson);
            json = myJson
            
          });
          GetFiltro(json)
         //console.log("JSON",json);
          
      }
      useEffect(()=>{
        getData()
      },[])*/
  const actualizar = (e, ubicacion) => {
    //console.log("Ciudad: ", e.target.value)

    if (ubicacion == "Ciudad") {
      Filtro.map(function (ciudad) {
        if (ciudad["Ciudad"] == e.target.value) {
          GetBarrios(ciudad["Barrios"]);
          //console.log("entra:", ciudad["Barrios"])
        } else {
          //console.log("NO entra")
        }
      });
      //console.log("Antes", Peticion)
      var list = "";
      list = Peticion;
      list[0].ciudad = e.target.value;
      //console.log("listA: ", list[0].ciudad)
      GetPeticion(list);
    }

    if (ubicacion == "Barrio") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].barrio = e.target.value;

      //console.log("listA: ", list2[0].barrio)
      GetPeticion(list2);
    }
    if (ubicacion == "Tipo") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].Tipo = e.target.value;

      //console.log("listA: ", list2[0].Tipo)
      GetPeticion(list2);
    }
    if (ubicacion == "Estrato") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].estrato = e.target.value;

      //console.log("listA: ", list2[0].estrato)
      GetPeticion(list2);
    }

    if (ubicacion == "precioMax") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].precioMax = e.target.value;

      //console.log("listA: ", list2[0].precioMax)
      GetPeticion(list2);
    }
    if (ubicacion == "precioMin") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].precioMin = e.target.value;

      //console.log("listA: ", list2[0].precioMin)
      GetPeticion(list2);
    }

    if (ubicacion == "Habitaciones") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].habitaciones = e.target.value;

      //console.log("listA: ", list2[0].habitaciones)
      GetPeticion(list2);
    }

    if (ubicacion == "baños") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].baños = e.target.value;

      //console.log("listA: ", list2[0].baños)
      GetPeticion(list2);
    }
    if (ubicacion == "Fernte") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].areafrente = e.target.value;

      //console.log("listA: ", list2[0].areafrente)
      GetPeticion(list2);
    }
    if (ubicacion == "Fondo") {
      //console.log("Antes", Peticion)
      var list2 = "";
      list2 = Peticion;
      list2[0].areafondo = e.target.value;

      //console.log("listA: ", list2[0].areafondo)
      GetPeticion(list2);
    }
    //console.log("Peticion: ", Peticion)
  };

  const buscar = async (datos) => {
    const respuesta = await clienteAxios.post("/api/casas/Filtrar", datos);
    //console.log("datos: *", respuesta.data.casa)
    guardarImagenes2({ Cambio: false, Lista: respuesta.data.casa });

    //console.log(error.response.data.msg);
  };

  const Filtrar = (e) => {
    e.preventDefault();

    var datos = {};
    var Pet = [];
    if (Peticion[0].ciudad != "") {
      //datos.push({"ciudad": Peticion[0].ciudad})
      //Pet.push(Peticion)
      //Pet[0][0].splice(2)
      datos = Object.assign({ ciudad: Peticion[0].ciudad }, datos);
      //console.log("push: eliminado 2,1 ", datos)
    } else {
      //console.log("error: ", Peticion[0].ciudad)
    }
    if (Peticion[0].barrio != "") {
      //datos.push({"barrio": Peticion[0].barrio})
      datos = Object.assign({ barrio: Peticion[0].barrio }, datos);
      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].barrio)
    }
    if (Peticion[0].Tipo != "") {
      //datos.push({"barrio": Peticion[0].barrio})
      datos = Object.assign({ tipo: Peticion[0].Tipo }, datos);
      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].Tipo)
    }
    if (Peticion[0].estrato != "") {
      //datos.push({"estrato": parseInt(Peticion[0].estrato) })
      datos = Object.assign({ estrato: parseInt(Peticion[0].estrato) }, datos);

      //console.log("push: ", datos)
    } else {
      //console.log("error: ", parseInt(Peticion[0].estrato))
    }
    if (Peticion[0].precioMax != "" && Peticion[0].precioMin != "") {
      //datos.concat({"precio": parseInt(Peticion[0].precio)})

      datos = Object.assign(
        {
          precio: {
            $lt: parseInt(Peticion[0].precioMax) + 1,
            $gt: parseInt(Peticion[0].precioMin) - 1,
          },
        },
        datos
      );

      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].precioMax)
    }
    if (Peticion[0].precioMin != "" && Peticion[0].precioMax == "") {
      //datos.concat({"precio": parseInt(Peticion[0].precio)})

      datos = Object.assign(
        { precio: { $gt: parseInt(Peticion[0].precioMin) - 1 } },
        datos
      );

      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].precioMin)
    }
    if (Peticion[0].precioMax != "" && Peticion[0].precioMin == "") {
      //datos.concat({"precio": parseInt(Peticion[0].precio)})

      datos = Object.assign(
        { precio: { $lt: parseInt(Peticion[0].precioMax) + 1 } },
        datos
      );

      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].precioMin)
    }

    if (Peticion[0].habitaciones != "") {
      //datos.concat({"precio": parseInt(Peticion[0].precio)})
      datos = Object.assign(
        { habitaciones: parseInt(Peticion[0].habitaciones) },
        datos
      );
      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].habitaciones)
    }

    if (Peticion[0].baños != "") {
      //datos.concat({"precio": parseInt(Peticion[0].precio)})
      datos = Object.assign({ baños: parseInt(Peticion[0].baños) }, datos);
      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].baños)
    }

    if (Peticion[0].areafrente != "") {
      //datos.concat({"precio": parseInt(Peticion[0].precio)})
      datos = Object.assign(
        { "area.ancho": { $gt: parseInt(Peticion[0].areafrente) - 1 } },
        datos
      );
      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].areafrente)
    }
    if (Peticion[0].areafondo != "") {
      //datos.concat({"precio": parseInt(Peticion[0].precio)})
      datos = Object.assign(
        { "area.largo": { $gt: parseInt(Peticion[0].areafondo) - 1 } },
        datos
      );
      //console.log("push: ", datos)
    } else {
      //console.log("error: ", Peticion[0].areafondo)
    }

    //console.log("Ciudad: ", e.target.value)
    //Pet = Object.assign(datos);
    //console.log("error:******* ", datos)
    buscar(datos);
  };

  const limpiarDatos = (e) => {
    var datos = {};
    datos = Object.assign({ ciudad: "" }, datos);
    datos = Object.assign({ barrio: "" }, datos);
    datos = Object.assign({ tipo: "" }, datos);
    datos = Object.assign({ estrato: "" }, datos);
    datos = Object.assign({ barrio: "" }, datos);
    datos = Object.assign({ precio: "" }, datos);
    datos = Object.assign({ habitaciones: "" }, datos);
    datos = Object.assign({ baño: "" }, datos);
    datos = Object.assign({ "area.ancho": "" }, datos);
    datos = Object.assign({ "area.largo": "" }, datos);

    buscar(datos);
  };

  return (
    <form>
      <div className="form-group text-center">
        <select
          placeholder="hola"
          className="form-control"
          onChange={(e) => actualizar(e, "Tipo")}
          id="exampleFormControlSelect1"
        >
          <option defaultValue>Tipo de Inmueble</option>
          <option>Apartamento</option>
          <option>Casa</option>
          <option>Lote</option>
          <option>Apartaestudio</option>
          <option>Casa Campestre</option>
          <option>Edificio</option>
        </select>
      </div>
      <div className="form-group text-center">
        <select
          className="form-control"
          onChange={(e) => actualizar(e, "Ciudad")}
          id="exampleFormControlSelect1"
        >
          <option defaultValue>Ciudad</option>
          {Filtro.map((filtro) => (
            //console.log("Map Get Casas: ", imagen)

            <option key={filtro["Ciudad"]}>{filtro["Ciudad"]}</option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <select
          className="form-control"
          onChange={(e) => actualizar(e, "Barrio")}
          id="exampleFormControlSelect1"
        >
          <option defaultValue>Barrio</option>
          {Barrios.map((Barrio) => (
            //console.log("Map Get Casas: ", imagen)

            <option key={Barrio}>{Barrio}</option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <select
          className="form-control"
          onChange={(e) => actualizar(e, "Estrato")}
          id="exampleFormControlSelect1"
        >
          <option defaultValue>Estrato</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <input
              className="form-control"
              onChange={(e) => actualizar(e, "precioMin")}
              placeholder="Valor Min."
              id="exampleFormControlSelect1"
            ></input>
          </div>
          <div className="col">
            <input
              className="form-control"
              onChange={(e) => actualizar(e, "precioMax")}
              placeholder="Valor Max."
              id="exampleFormControlSelect1"
            ></input>
          </div>
        </div>
      </div>
      <div className="form-group">
        <select
          className="form-control"
          onChange={(e) => actualizar(e, "Habitaciones")}
          id="exampleFormControlSelect1"
        >
          <option defaultValue>Habitaciones</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      </div>
      <div className="form-group">
        <select
          className="form-control"
          onChange={(e) => actualizar(e, "baños")}
          id="exampleFormControlSelect1"
        >
          <option defaultValue>Baños</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <input
              className="form-control"
              onChange={(e) => actualizar(e, "Fernte")}
              placeholder="Frente Min."
              id="exampleFormControlSelect1"
            ></input>
          </div>
          <div className="col">
            <input
              className="form-control"
              onChange={(e) => actualizar(e, "Fondo")}
              placeholder="Fondo Min."
              id="exampleFormControlSelect1"
            ></input>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-outline-primary btn-lg btn-block"
        onClick={Filtrar}
        style={{
          backgroundColor: "#4c0000",
          border: "0",
          fontSize: "25px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        Filtrar
      </button>
      <button
        type="submit"
        className="btn btn-outline-primary btn-lg btn-block"
        onClick={limpiarDatos}
        style={{
          backgroundColor: "#4c0000",
          border: "0",
          fontSize: "25px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        Limpiar
      </button>
    </form>
  );
};

export default Filtro;
