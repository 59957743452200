import React, { useState, useEffect, Fragment } from 'react';
import './Nosotros.css';


const Nosotros = ({cambiarheader,headers}) => {

    const style = {
        marginTop: "6vh",
        
        textAlign: "center",
        
}
    useEffect(() => {
        if(headers["color"] === "#ffff")
            cambiarheader({"position": "relative", "color":"#500f0f", "display":"flex", "Img":"/img/Header-Aldia2.png", "Login":"/img/Icono-login2.png"})
    })

    return(
        
            <div className="container scroll-invisible" style={{height:"100vh", overflowY: "scroll"}}>   
              <div className="row row-content align-items-center">
            <div className="col-12 col-centered" style={style}>
            <h1 style={{color:"#500f0f", fontWeight: "bold"}}>Sobre nosotros</h1>
            <p style={{textAlign: "justify", marginTop:"20px"}}>Aldia inmobiliaria Es una compañía Colombiana especializada en la venta de propiedades inmobiliarias, que destaca por su trato cercano y totalmente personalizado. Su principal misión es encontrarle a cada cliente una vivienda que cumpla plenamente con sus expectativas. Aldia Inmobiliaria lleva años desarrollando su actividad dentro del sector inmobiliario, por lo que cuenta con una gran experiencia.</p>            
             </div>
            </div>
            <div className="container" style={{marginTop:"100px"}}>
            <div className="row">
                
                    <div className="col-lg-4 col-sm-12 col-md-6" style={{textAlign: "center"}}>
                    <img src="/img/Icono-integridad.png" />
                    <h3 style={{color: "#500f0f"}}>Integridad</h3>
                    <p style={{textAlign: "justify"}}> Es un valor clave dentro del sector inmobiliario. Nosotros queremos que nuestros diferentes stakeholders depositen su confianza en Aldia inmobiliaria, por ello llevamos a cabo prácticas totalmente transparentes.
                    </p>  
                    </div>
                    <div className="col-lg-4 col-sm-12 col-md-6" style={{textAlign: "center"}}>
                    <img src="/img/Icono-profesionalismo.png" />
                    <h3 style={{color: "#500f0f"}}>Profesionalismo</h3>
                    <p style={{textAlign: "justify"}}>  Aldia inmobiliaria cuenta con un experimentado equipo humano especializado en la comercialización de inmuebles. La máxima prioridad es encontrar siempre la mejor solución para nuestros clientes  
                  </p>  
                    </div>
                    <div  className="col-lg-4 col-sm-12 col-md-6" style={{textAlign: "center"}}>
                    <img src="/img/Icono-servicio.png" />
                    <h3 style={{color: "#500f0f"}}>Servicio</h3>
                    <p style={{textAlign: "justify"}}> Operamos en el sector inmobiliario desde hace años; este hecho hace que conozcamos en profundidad cuáles son las diferentes particularidades y necesidades del sector. Sólo con un servicio excelente conseguiremos satisfacer a nuestros clientes.
                  </p>  
                    </div>

                </div>
            </div>
        </div>
 
            
        
        
    )


};
    
        

export default Nosotros;