import React, { useState, useEffect } from 'react'
import UploadService from '../../services/upload.services'
import './admin.css'
import clienteAxios from '../../config/Axios';
import swal from 'sweetalert';

const Upload = ({pathImage,setPathImage, CrearCasas, setCrearCasas, setImagenes, Imagenes,imagesE,Tipo,setimagesE,Editar,aux,apiUrlState}) => {

    const [name, setName ] = useState("")
    const [file, setFile] = useState(["Null","Null","Null","Null","Null","Null","Null","Null","Null"])
    const [Index, setIndexx] = useState()
    const [Index2, setIndexx2] = useState(apiUrlState+"Upload.png")
    const [carga, setcarga] = useState("")
    const [extension, setExtension] = useState(["Null","Null","Null","Null","Null","Null","Null","Null","Null"])

    //console.log("--CrearCasas", CrearCasas)
    //console.log("-setCrearCasas", setCrearCasas)setPathImage={setPathImage}
                  
    
    //console.log("pathImage: * " , pathImage)
    useEffect(() => {
        if( CrearCasas.Images.length > 0 && !Tipo){
            registrarCasa(CrearCasas);
            //console.log("Insertó----")
            setCrearCasas({
                ...CrearCasas,
                Images : []
              
              })

        }
           
           
      })
      const Ciudades = async (Ciudad, Barrio) => {
          
        try {
            const respuesta = await clienteAxios.post('/api/casas/Filtro' , {"Ciudad":Ciudad,"Barrios":Barrio});
            
            
              //console.log("Filtross", JSON.stringify(respuesta.data[0]))
              
            //console.log(imagenes2["Lista"].length, "Tamaño")
            //console.log(imagenes2["Lista"][imagenes2["Lista"].length - 1]["codigo"])
            //guardarIncrement(imagenes2["Lista"][imagenes2["Lista"].length - 1]["codigo"]+1)
            
        } catch (error) {
            
            //console.log("**********")
        }
    }
    
    const usuarioAutenticado = async () => {
          
        try {
            const respuesta = await clienteAxios.get('/api/casas');
            
            
             // console.log(JSON.stringify(respuesta.data[respuesta.data.length - 1]["codigo"])+ "777777777777")
              const x = (respuesta.data[respuesta.data.length - 1]["codigo"] + 1).toString()
              //console.log(x + "**Inclremento")
              setName(x)
            //console.log(imagenes2["Lista"].length, "Tamaño")
            //console.log(imagenes2["Lista"][imagenes2["Lista"].length - 1]["codigo"])
            //guardarIncrement(imagenes2["Lista"][imagenes2["Lista"].length - 1]["codigo"]+1)
            
        } catch (error) {
            
            //console.log("*****E*****")
        }
    }
    usuarioAutenticado()

    const registrarCasa = async datos => {
        
        try {
            Ciudades(CrearCasas.ciudad, CrearCasas.barrio)
            const respuesta = await clienteAxios.post('/api/casas', datos);
            //window.location.href = '/Insertar';
            //console.log("Insertó casa")
            

        } catch (error) {
            //console.log("Error al crear casa");
            
        }
    }

    const editarCasa = async datos => {
        //console.log('/api/casas/'+Editar["id"])
        //console.log("DATOS: ", datos)
        try {
            Ciudades(CrearCasas.ciudad, CrearCasas.barrio)
            const respuesta = await clienteAxios.put('/api/casas/'+Editar["id"], datos);
            //window.location.href = '/Insertar';
            

        } catch (error) {
            //console.log("Error al editar casa");
            
        }
    }

    /*useEffect(() => {
        //console.log(props.pathImage)
    })*/

    const sendImage = (e) => { 
        e.preventDefault()
        var id = 0
        var list = []
        for (var i = 0; i < file.length; i++) {
            if(file[i] != "Null"){
                
                const nombre = name+"-"+i ///name+"-"+i
                list.push(nombre+"."+extension[i])
                UploadService.sendImages(nombre, file[i]).then((result) =>{
                    //console.log("El resultado es : ", result)
                    //console.log(name, "front")
                
                })
                
                

            }
            
         }

         setCrearCasas({
            ...CrearCasas,
            Images : list
          
          })


          if (CrearCasas.Images.length > 0 ){
            registrarCasa(CrearCasas);
            //console.log("si guarda imagenes")
            
          }

          swal({
            title: "Casa Creada con exito",
            icon: "success",
            dangerMode: false,
          })
          .then(willDelete => {
            if (willDelete) {
                
              window.location.href = '/Menu';
              //swal("Deleted!", "Your imaginary file has been deleted!", "success");
            
              
            
            }
          });
          

          //console.log("CrearCasas Imagenes**", CrearCasas)

          //setCrearCasas([])
    }


    const editImage = async (e) => {
        e.preventDefault()
        var id = 0
        var list = aux
        for (var i = 0; i < file.length; i++) {
            if(file[i] != "Null"){
                var codigo = CrearCasas.codigo 
                const nombre = codigo+"-"+i ///name+"-"+i
                //list.push(nombre+"."+extension[i])
                list[i]= nombre+"."+extension[i]
                const respuesta = await clienteAxios.post('/api/casas/DeleteImg', {"img":nombre+"."+extension[i]});
                UploadService.sendImages(nombre, file[i]).then((result) =>{
                    //console.log("El resultado es : ", result)
                    //console.log(name, "front")
                
                })
                
                

            }
            
         }
        //var lista = list.concat(aux)
        //console.log("list",list)
         setCrearCasas({
            ...CrearCasas,
            Images : list
          
          })


          if (CrearCasas.Images.length > 0 ){
            editarCasa(CrearCasas);
            //console.log("si EDITA imagenes")
            
          }

          swal({
            title: "Casa Editada con exito",
            icon: "success",
            dangerMode: false,
          })
          .then(willDelete => {
            if (willDelete) {
                
              window.location.href = '/Menu';
              //swal("Deleted!", "Your imaginary file has been deleted!", "success");
            
              
            
            }
          });
          

    }

    const onFileChanges = (e, number) => {
        //console.log("++++++++++++++++", e.target.files[0].name)
        //console.log(Index)
        if(e.target.files && e.target.files.length > 0){
            const file_img = e.target.files[0]
            const objeto = URL.createObjectURL(e.target.files[0])
            //console.log("Objeto", objeto)
            if(file_img.type.includes("image")){
                const reader = new FileReader()
                reader.readAsDataURL(file_img)

                reader.onload = function load(){
                    const x = 6
                    let uploads = ""
                    if(Tipo){
                        uploads =  imagesE
                    }else{
                        uploads = pathImage
                    }
                    //const uploads = pathImage
                    
                    uploads[number] = objeto //reader.result
                    //console.log("uploads: ",  uploads)
                    //setPathImage(reader.result)
                    //setPathImage(uploads)
                    setIndexx2(objeto)
                    if(Tipo){
                        setimagesE(uploads)
                    }else{
                        setPathImage(uploads)
                    }
                    //console.log("pathImage", uploads)
                }
                //var list =JSON.parse(JSON.stringify(file))
                
                //setIndexx(Index+1)
                //console.log(Index)
                
                var list = file
                //console.log("file_img", file_img)
                //list.push(file_img)
                list[number]=file_img
                setFile(list)
                var ext = extension
                ext[number]=e.target.files[0].name.split(".")[1]
                setExtension(ext)
                //console.log("State", file)
                //console.log("EXTENSION_ARCHIVI", extension)

            }else {
                //console.log("No cumple")
            }
        }
    }



    return (
        
        <form>
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                //onChange={onFileChanges}
                                onChange={ (e) => onFileChanges(e, 0)}
                                
                            />
                           { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[0]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[0]} alt="Image" />
                        }
                            
                            
                            
                    </div>

                        <a 
                            id = "0"
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                            //onChange={ (e) => setName("e.target.value")}
                            //onChange={ (e) => setIndexx(0)}
                        
                        />

                        <br />
                    </div>
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                onChange={ (e) =>  onFileChanges(e , 1)}
                            />
                            

                            { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[1]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[1]} alt="Image" />
                        }

                        </div>

                        <a 
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                            //onChange={ (e) => setName("e.target.value")}
                        
                        />

                        <br />
                    </div>
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                //onChange={onFileChanges}
                                onChange={ (e) => onFileChanges(e , 2)}
                            />

                        { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[2]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[2]} alt="Image" />
                        }

                        </div>

                        <a 
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                            //onChange={ (e) => setName("e.target.value")}
                        
                        />

                        <br />
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                //onChange={onFileChanges}
                                onChange={ (e) => onFileChanges(e , 3)}
                                
                            />

                        { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[3]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[3]} alt="Image" />
                        }

                    </div>

                        <a 
                            id = "0"
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                            //onChange={ (e) => setName("e.target.value")}
                            //onChange={ (e) => setIndexx(0)}
                        
                        />

                        <br />
                    </div>
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                onChange={ (e) =>  onFileChanges(e , 4)}
                            />
                            
                            
                            { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[4]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[4]} alt="Image" />
                            }

                        </div>

                        <a 
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                            //onChange={ (e) => setName("e.target.value")}
                        
                        />

                        <br />
                    </div>
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                //onChange={onFileChanges}
                                onChange={ (e) => onFileChanges(e , 5)}
                            />

                            { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[5]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[5]} alt="Image" />
                            
                            }

                        </div>

                        <a 
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                            //onChange={ (e) => setName("e.target.value")}
                        
                        />

                        <br />
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                //onChange={onFileChanges}
                                onChange={ (e) => onFileChanges(e , 6)}
                                
                            />

                            { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[6]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[6]} alt="Image" />
                            
                            }
                            

                    </div>

                        <a 
                            id = "0"
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                            //onChange={ (e) => setName("e.target.value")}
                            //onChange={ (e) => setIndexx(0)}
                        
                        />

                        <br />
                    </div>
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                onChange={ (e) =>  onFileChanges(e , 7)}
                            />
                            

                            { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[7]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[7]} alt="Image" />
                            
                            }

                        </div>

                        <a 
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                            //onChange={ (e) => setName("e.target.value")}
                        
                        />

                        <br />
                    </div>
                    <div className="col-sm">
                    <div className="input-file">
                            <input
                                type="file"
                                placeholder="File"
                                //onChange={onFileChanges}
                                onChange={ (e) => onFileChanges(e , 8)}
                            />

                            { (!Tipo) ?
                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={pathImage[8]} alt="Image" />
                           
                            :

                            <img className="img-fluid img-thumbnail image" style={{objectFit: "cover"}} src={imagesE[8]} alt="Image" />
                            
                            }

                        </div>

                        <a 
                            type = "text"
                            placeholder = "Ingresa Nombre"
                            className= "name-picture mt-2"
                           // onChange={ (e) => setName("e.target.value")}
                        
                        />

                        <br />
                    </div>
                    
                </div>
            </div>
            { (!Tipo) ?
            <button type="submit" className="btn btn-outline-primary btn-lg btn-block" onClick={sendImage} style={{backgroundColor:"#4c0000", border:"0", fontSize:"25px", fontWeight: "bold", color:"white"}}>Guardar</button>
            :
            <button type="submit" className="btn btn-outline-primary btn-lg btn-block" onClick={editImage} style={{backgroundColor:"#4c0000", border:"0", fontSize:"25px", fontWeight: "bold", color:"white"}}>Editar</button>
            }
        
            </form>
        
    )

}


export default Upload
