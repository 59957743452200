
import {Link} from 'react-router-dom';
import './Header.css'
import React, { useState, useEffect, Fragment , useContext} from 'react';
import AuthContext from '../context/autenticacion/authContext';
import Filtro from './Inmueble/Filtro';

const Header = ({headers, cambiarheader, guardarImagenes2}) => {

    const authContext = useContext(AuthContext);
    const { usuarioAutenticado, cerrarSesion } = authContext;
    useEffect(() => {
        usuarioAutenticado();
        // eslint-disable-next-line
    }, []);
    
    const colores = headers.color + " !important"
    const header = {
        position: headers.position,
        display: headers.display
        
      };
     
    const text = {
        color: headers.color,
        
        
        
        
    }

    const aldia = {
        color: headers.color,
        /*fontSize:"85px",*/
        fontWeight: "bold"

        
    }


    const margenes = {
        paddingLeft: "4vw"
        
      };

      const nav = {
        backgroundColor: "transparent !important",
        border: "0"

    
      };
 
    const Rutas = (ruta) =>{
        window.location.href = '/Inmuebles';
        /*if( ruta == 'Inicio'){
            window.location.href = '/home';
            cambiarheader({"position": "absolute", "color":"white"})
        }
        if( ruta == 'Inmuebles'){
            window.location.href = '/Inmuebles';
            cambiarheader({"position": "relative", "color":"#500f0f"})
        }
        if( ruta == 'Nosotros'){
            window.location.href = '/Nosotros';
            cambiarheader({"position": "relative", "color":"#500f0f"})
        }
        if( ruta == 'Contacto'){
            window.location.href = '/Contacto';
            cambiarheader({"position": "relative", "color":"#500f0f"})
        }
        if( ruta == 'login'){
            //window.location.href = '/login';
            cambiarheader({"position": "relative", "color":"#500f0f"})
        }*/
        
        
    }
    return(
    <div>
        { (headers.display === "flex") ?
            <div style={header} id="header">
                <nav className="navbar navbar-expand-lg navbar-light" style={text,{width:"99vw"}}>
                    <a className="navbar-brand"  href="/" style={aldia}>
                        <img className="Aldia" src={headers.Img}></img>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse col-sm desplegar " id="navbarNavAltMarkup" >
                        <div className="navbar-nav col-sm" >
                        <Link to="/"> 
                        <div className="col-sm " style={{textAlign: "right"}}>
                            <div className="row">
                            <div className="col-2" className="ocultar"><img className="icons" src="/img/inicio-blanco.png" /></div>
                            <div className="col-8 col-md-12 col-lg-12"><p className="nav-item nav-link inicio " style={text}> Inicio</p></div>
                            </div>
                        </div>
                        </Link>
                        <Link to="/Inmuebles" onClick={Rutas}>  
                        <div className="col-sm">
                        <div className="row">
                            <div className="col-2" className="ocultar"><img className="icons"  src="/img/inmuebles-blanco.png" /></div>
                            <div className="col-8 col-md-12 col-lg-12"><p className="nav-item nav-link  active inicio" href="#" style={text} >Inmuebles <span className="sr-only">(current)</span></p></div>
                        </div>
                        
                        </div> 
                        </Link>
                        <Link to="/Nosotros"> 
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-2" className="ocultar"><img  className="icons" src="/img/sobre-nosotros-blanco.png" /></div>
                                <div className="col-10 col-md-12 col-lg-12"><p className="nav-item nav-link inicio" href="#" style={text}  >Sobre Nosotros</p></div>
                            </div>
                        
                        </div>   
                        </Link>
                        <Link to="/Contacto">    
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-2" className="ocultar"><img className="icons"  src="/img/contactanos blanco.png" /></div>
                                <div className="col-10 col-md-12 col-lg-12"><p className="nav-item nav-link inicio" href="#" style={text}  >Contáctanos</p></div>
                            </div>
                        
                        </div>
                        </Link>
                        <Link to="/login" className="col-sm" style={{textAlign:"right"}}> 
                        <p className="nav-item nav-link col-sm logeo"  style={text} >
                            <img className="login"  src={headers.Login} style={{height:"40px", width:"40px"}}></img>
                        </p>
                        <div className="row ">
                                <div className="col-2" className="ocultar"><img className="icons"  src="/img/Icono-login.png" /></div>
                                <div className="ocultar col-10 col-md-12 col-lg-12 " style={{paddingTop: "2px", color: "white"}}><p className="nav-item nav-link inicio" href="#" style={text}  >Iniciar sesion</p></div>
                            </div>
                        </Link>
                        </div>
                    </div>
                </nav>
                <div className="row filtross">
                    <p>
                    <a className="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        Link with href
                    </a>
                    </p>
                    <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                    <Filtro 
                            guardarImagenes2 = {guardarImagenes2}
                    />
                    </div>
                    </div>

                </div>
                
            </div>
            

        :
            <div className="container" style={header}>
            <div className="row">
              <div className="col-4 col-sm-6 col-md-6 col-lg-10">
              <Link to="/login" className="col-sm" style={{textAlign:"right"}}> 
                <img src={headers.Login} className="icono"></img>
              </Link>
              </div>
              <div className="col-4 col-sm-3 col-md-2 col-lg-1 center">
                <Link to="/Inmuebles" className="col-sm" style={{textAlign:"right"}}> 
                    <button type="button" className="btn btn-danger"  style={{width: "100%", backgroundColor:"#4c0000", border:"0", fontSize:"15px", fontWeight: "bold"}}><img src="/img/inmuebles-blanco.png" style={{height: "40px"}}></img></button>
                </Link>
              </div>
              <div className="col-4 col-sm-3 col-md-2 col-lg-1 center">
              <button type="button" className="btn btn-danger" onClick={() => cerrarSesion() }  style={{backgroundColor:"#4c0000", border:"0", fontSize:"25px", fontWeight: "bold"}}>Salir</button>
            
              </div>
              
            </div>
          </div>

        }
    </div> 
    );

}

export default Header;