import React, { useState, useEffect, Fragment, useContext } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import "./static/css/login.css";
import clienteAxios from '../../config/Axios'
//import axios from 'axios';
import  AlertaContext from '../../context/alertas/alertaContext'
import AuthContext from '../../context/autenticacion/authContext';
//import express from "express";
const Login = (props) => {//{headers,cambiarheader}
  
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    const authContext = useContext(AuthContext);
    const { mensaje, autenticado, iniciarSesion } = authContext;

    useEffect(() => {
        if( props.headers["display"] === "flex"){
            props.cambiarheader({"position": "relative", "color":"#ffff", "display":"none","Img":"/img/Header-Aldia2.png", "Login":"/img/Icono-login2.png"})
        }
        if(autenticado) {
            //props.history.push('/VerEditar');
            window.location.href = '/Menu';
            //console.log(autenticado)
        }
        if(mensaje) {
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }
        
    }, [mensaje, autenticado, props.history])

    const [usuario, guardarUsuario] = useState({
        nombre: '',
        contraseña: ''
    });

    // extraer de usuario
    const { nombre, contraseña } = usuario;

    const onChange = e => {
        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value
        })
    }

    // Cuando el usuario quiere iniciar sesión
    const onSubmit = e => {
        e.preventDefault();

        // Validar que no haya campos vacios
        if(nombre.trim() === '' || contraseña.trim() === '') {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
        }

        // Pasarlo al action
        iniciarSesion({ nombre, contraseña });
    }
        
            

        //const app = express();

        
        /*app.use(express.json({extended: true}));

        const PORT = process.env.PORT || 3000;

        app.use('/api/usuarios', require('../../routes/usuarios'));

        app.get('/', (req, res) => {
            res.send("hola mundo")
        })
        app.listen(PORT, () => {
            console.log(`El servidor esta corriendo en el puerto ${PORT}`);
        })*/
    
    
    
    return(
            <div className="container-fluid scroll-invisible" style={{height:"100vh", overflowY: "scroll", maxWidth: "480px"}}>
                {alerta ? (<h1>{alerta.msg}</h1>): null}
                <div className="card" style={{border: "0"}}>
                    <img className="card-img-top" src={props.headers.Img} alt="Card image cap" style={{objectFit: "scale-down"}}/>
                    <div className="card-body">
                        <div className="form-usuario">
                                <div className="contenedor-form sombre-dark" style={{textAlign: "center", color: "#4c0000"}}>
                                    <form
                                        onSubmit={onSubmit}
                                    >
                                    <div className="campo-form" style={{margin:"15px"}}>
                                            <input 
                                                type="email"
                                                className="form-control"
                                                id="nombre"
                                                name="nombre"
                                                placeholder="usuario"
                                                onChange={(e) => onChange(e)}
                                            />
                                    </div>
                                        <div className="campo-form" style={{margin:"15px"}}>
                                            <input 
                                                type="password"
                                                className="form-control"
                                                id="contraseña"
                                                name="contraseña"
                                                placeholder="contraseña"
                                                onChange={(e) => onChange(e)}
                                            />
                                        </div>
                                       {/* <div className="campo-form" style={{margin:"15px"}}>
                                         <Link to="/Menu">   
                                            <input type="submit" className="btn btn-primary" value="Ingresar"  style={{backgroundColor:"#4c0000", border:"0"}}> 
                                            </input>
                                        </Link>
                                        </div>*/}
                                        <div className="campo-form" style={{margin:"15px"}}>
                                        <input type="submit" className="btn btn-primary" value="Ingresar"  style={{backgroundColor:"#4c0000", border:"0"}}> 
                                        </input>
                                        </div>
                                        
                                    </form>
                                    
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default Login;