import React, { useState, useEffect, Fragment , useContext} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';


//import express from "express";
import AuthContext from '../../context/autenticacion/authContext';

const Menu = (props) => {//,{headers,cambiarheader}
    /*const authContext = useContext(AuthContext);
    const { usuarioAutenticado, cerrarSesion } = authContext;*/
    useEffect(() => {
            if(props.headers["display"] === "flex")
            props.cambiarheader({"position": "relative", "color":"#ffff", "display":"inline", "Login":"/img/Header-Aldia2.png"})

                //usuarioAutenticado();
                // eslint-disable-next-line
        })


    return(
            <div className="container-fluid scroll-invisible" style={{height:"100vh", overflowY: "scroll", maxWidth: "480px"}}>
                <div className="card" >
                    <img className="card-img-top" src="/img/Casa2.png" alt="Card image cap" />
                    <div className="card-body">
                        <div className="form-usuario">
                                <div className="contenedor-form sombre-dark" style={{textAlign: "center", color: "#4c0000"}}>
                                    <form>
                                    <div className="campo-form" style={{margin:"15px"}}>
                                    <Link to="/Insertar">
                                        <div className="btn btn-primary btn-block" style={{backgroundColor:"#4c0000", border:"0"}}>Crear</div>
                                    </Link>
                                    </div>
                                        <div className="campo-form" style={{margin:"15px"}}>
                                        <Link to="/VerEditar">
                                            <div className="btn btn-primary btn-block" style={{backgroundColor:"#4c0000", border:"0"}}>Ver / Editar</div>
                                        </Link>
                                        </div>
                                    </form>
                                   
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default Menu;