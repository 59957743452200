import React, { useState, useEffect, Fragment } from "react";
import "./Contacto.css";
import clienteAxios from "../../config/Axios";
import swal from "sweetalert";

const Contacto = ({ headers, cambiarheader }) => {
  const [Correo, GetCorreo] = useState({
    Nombre: "",
    Apellido: "",
    Telefono: "",
    Email: "",
    Mensaje: "",
  });
  //console.log("Correo: " + JSON.stringify(Correo) )
  const style = {
    marginTop: "10vh",
  };
  useEffect(() => {
    if (headers["color"] === "#ffff")
      cambiarheader({
        position: "relative",
        color: "#500f0f",
        display: "flex",
        Img: "/img/Header-Aldia2.png",
        Login: "/img/Icono-login2.png",
      });
  });
  const actualizar = (e, categoria) => {
    e.preventDefault();

    if (categoria == "Nombre") {
      GetCorreo({
        ...Correo,
        Nombre: e.target.value,
      });
    }
    if (categoria == "Apellido") {
      GetCorreo({
        ...Correo,
        Apellido: e.target.value,
      });
    }
    if (categoria == "Telefono") {
      GetCorreo({
        ...Correo,
        Telefono: e.target.value,
      });
    }
    if (categoria == "Email") {
      GetCorreo({
        ...Correo,
        Email: e.target.value,
      });
    }
    if (categoria == "Mensaje") {
      GetCorreo({
        ...Correo,
        Mensaje: e.target.value,
      });
    }
  };

  const Concatenar = async (e, categoria) => {
    try {
      var Mensaje =
        "El Usuario " +
        Correo.Nombre +
        " " +
        Correo.Apellido +
        " " +
        "solicita publicacion en la pagina " +
        "Correo: " +
        Correo.Email +
        " " +
        "Telefono: " +
        Correo.Telefono +
        " Solicitud: " +
        Correo.Mensaje;
      const respuesta = await clienteAxios.post("/api/casas/Correo", {
        MensajeCorreo: Mensaje,
      });
      swal(
        "Mensaje enviado!",
        "Pronto nos pondremos en contacto contigo",
        "success"
      );
      //console.log("Correo Enviado respuesta", JSON.stringify(respuesta.data));
      GetCorreo({
        Nombre: "",
        Apellido: "",
        Telefono: "",
        Email: "",
        Mensaje: "",
      });
    } catch (error) {
      //console.log("*Error***")
    }
  };

  return (
    <div
      className="container scroll-invisible"
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <div className="row row-content align-items-center">
        <div className="col-12 col-centered" style={style}>
          <div style={{ textAlign: "center" }}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  value={Correo.Nombre}
                  onChange={(e) => actualizar(e, "Nombre")}
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="Nombre"
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  value={Correo.Apellido}
                  onChange={(e) => actualizar(e, "Apellido")}
                  type="text"
                  className="form-control"
                  id="inputPassword4"
                  placeholder="Apellido"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  value={Correo.Email}
                  onChange={(e) => actualizar(e, "Email")}
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="Email"
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  value={Correo.Telefono}
                  onChange={(e) => actualizar(e, "Telefono")}
                  type="text"
                  className="form-control"
                  id="inputPassword4"
                  placeholder="Telefono"
                />
              </div>
            </div>
            <div className="form-group">
              <textarea
                value={Correo.Mensaje}
                onChange={(e) => actualizar(e, "Mensaje")}
                type="text"
                className="form-control"
                id="inputAddress"
                placeholder="Mensaje"
                rows="12"
              />
            </div>
            <a
              className="btn btn-primary"
              style={{
                backgroundColor: "#500f0f",
                border: "0",
                margin: "15px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={(e) => Concatenar(e)}
            >
              Enviar
            </a>
            <a
              href="https://linktr.ee/aldia.inmobiliaria"
              target="_blank"
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#500f0f",
                border: "0",
                margin: "15px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              <img style={{ height: "30px" }} src="/img/Icono-whatsapp.png" />{" "}
              Contactar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
