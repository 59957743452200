/*import axios from 'axios';

const clienteAxios = axios.create({
    baseURL : process.env.REACT_APP_BACKEND_URL

});

export default clienteAxios;*/

import axios from "axios";

var RUTA = "https://api.aldiainmobiliaria.com";

var DEBUG = true;
//process.env['REACT_APP_BACKEND_URL'] = "http://181.55.127.197:4000"
if (DEBUG) {
  RUTA = "https://api.aldiainmobiliaria.com";
} else {
  RUTA = process.env.REACT_APP_BACKEND_URL;
}
const clienteAxios = axios.create({
  baseURL: "https://api.aldiainmobiliaria.com",
});

export default clienteAxios;
