import React, { useState, useEffect, Fragment } from 'react';
import Formulario from './Formulario';
import ListadoImagenes from './ListadoImagenes';
import Filtro from './Filtro';
import './Inmueble.css';
import { useParams } from "react-router";
import clienteAxios from '../../config/Axios';
//import Footer from './Footer';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
//import Login from './../components/auth/Login';

//import Header from './Header';

function Inmueble({imagenes2,guardarImagenes2,imagenes,paginaactual,totalpaginas,guardarPaginaActual,guardarDetalle,Detalles,cambiarheader,headers,apiUrlState}) {

    // definir la página anterior
    //console.log("Detalle: " + JSON.stringify(headers))
    var {Tipo} = useParams();
    var validador = true
    
    const [ CasasNew, GetCasasNew] = useState({Cambio: true, Lista: []});
    
    useEffect(() => {
      buscarcasas({"tipo": "Lote"})
      if( headers["color"] === "#ffff" || headers["display"] === "none"){
         cambiarheader({"position": "relative", "color":"#500f0f", "display":"flex", "Img":"/img/Header-Aldia2.png", "Login":"/img/Icono-login2.png"})}

         if(Tipo === "Lotes" && CasasNew["Cambio"]){
          buscarcasas({"tipo": "Lote"})

          Tipo = "vacio"
      }
         if(Tipo === "Casas" && CasasNew["Cambio"]){
          buscarcasas({"tipo": "Casa"})
          validador = false
          
          Tipo = "vacio"
      }
         if(Tipo === undefined && CasasNew["Cambio"]){
          buscarcasas({})
          validador = false
          
          Tipo = "vacio"
      }
            
    },[])


    const buscarcasas = async (datos) => {
      
      const respuesta = await clienteAxios.post('/api/casas/Filtrar', datos);
     GetCasasNew({Cambio: false, Lista: respuesta.data.casa})
     console.log("respuesta cantidad casas:",respuesta.data.casa)
     
  
      ////console.log(error.response.data.msg);
      
  }
    
    const paginaAnterior = () => {
      const nuevaPaginaActual = paginaactual - 1;
  
      if(nuevaPaginaActual === 0 ) return;
  
      guardarPaginaActual(nuevaPaginaActual);
    }
    //obtener fecha
    const fecha= new Date().getFullYear()
    // definir la pagina siguiente
    const paginaSiguiente = () => {
      const nuevaPaginaActual = paginaactual + 1;
  
      if(nuevaPaginaActual > totalpaginas ) return;
  
      guardarPaginaActual(nuevaPaginaActual);
    }
  return (

     
    <div style={{height: "100%"}}>
      <div className="container-fluid" style={{marginBottom: "50px"}}>  
        <div className="row">
          <div className="filtromovil">
            <div className="row ">
              <p>
                <img src="/img/filtro.png"  data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"
                style={{position: "absolute", zIndex: "0", right: "26%", border: "0", fontSize: "20px", height: "40px", top: "5%"}} />          
              </p>
              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  <Filtro 
                            guardarImagenes2 = {GetCasasNew}
                            imagenes2 = {CasasNew}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 filtro" style={{paddingTop:"5vh", textAlign:"center",color:'rgb(80, 15, 15)',fontFamily:"inherit"}}>
            <h1>Filtro</h1>
            <Filtro 
              guardarImagenes2 = {GetCasasNew}
              imagenes2 = {CasasNew}
            />         
          </div>
          <div className="col-sm align-self-center scrolling" style={{height: "100vh"}}> 
          {CasasNew.Lista.length === 0 ? 
          <p style={{color:'rgb(80, 15, 15)',fontFamily:"inherit",font:'red'}}>
            <h3 >No obtuvimos resultados para tus filtros</h3> 
            <br></br>          
            <h4 >¡Por favor inténtalo nuevamente!</h4>
            <br></br>                                        
            <div style={{color:'rgb(80, 15, 15)',fontFamily:"serif"}}>
              <ul><h4 >Tips para la búsqueda:</h4>
                <li> Si el barrio que buscas no aparece en nuestro listado posiblemente no tenemos inmuebles en ese sector</li>
                <li> Prueba buscando en un barrio aledaño o sin este filtro.</li>
                <li> Intenta de nuevo, reduciendo la cantidad de filtros.</li>
              </ul>
            </div>
       </p>
          
          
          : 
          
          <ListadoImagenes 
              imagenes={imagenes}
              imagenes2={CasasNew}
              guardarDetalle={guardarDetalle}
              Detalles={Detalles}
              apiUrlState={apiUrlState}
            /> }
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inmueble;
