import React, { useState, useEffect, Fragment } from "react";
//import Formulario from './components/Formulario';
//import ListadoImagenes from './components/ListadoImagenes';
//import Filtro from './components/Filtro';
import TagManager from "react-gtm-module";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Login2 from "./components/auth/Login2";
import Inmueble from "./components/Inmueble/Inmueble";
import Home from "./components/Home/Home";
import Header from "./components/Header";
//require('./db');
import Nosotros from "./components/Nosotros/Nosotros";
import Contacto from "./components/Contacto/Contacto";
import Detalle from "./components/Detalle/Detalle";
import Insertar from "./components/admin/inicioAdmin";
import Menu from "./components/admin/Menu";
import VerEditar from "./components/admin/Ver_Editar";
import Editarcasa from "./components/admin/Editar";
import AlertaState from "./context/alertas/alertaState";
import AuthState from "./context/autenticacion/authState";
import tokenAuth from "./config/token";
import RutaPrivada from "./components/rutas/RutaPrivada";
import clienteAxios from "./config/Axios";

//GTM-MPSRW8J
const tagManagerArgs = {
  gtmId: "GTM-NPZWT63",
};

TagManager.initialize(tagManagerArgs);
TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    path: "/",
  },
});

function App() {
  // state de la app
  //const [ busqueda, guardarBusqueda ] = useState('');

  let DEBUG = false;

  //State para peticiones al servidor, determinado por el estado del DEBUG. modificarApiUrlState
  const [apiUrlState] = useState(
    DEBUG ? "http://localhost:4000/" : "https://api.aldiainmobiliaria.com/"
  );

  const [imagenes, guardarImagenes] = useState({ Cambio: true, Lista: [] });
  const [imagenes2, guardarImagenes2] = useState({ Cambio: true, Lista: [] });
  const [Increment, guardarIncrement] = useState();
  const [cont, guardarcont] = useState(0);
  const [headers, cambiarheader] = useState({
    position: "absolute",
    color: "#ffff",
    display: "flex",
    Img: "/img/Header-Aldia.png",
    Login: "/img/Icono-login.png",
  });
  const [Detalles, guardarDetalle] = useState([
    {
      detalle: [],
      cambio: false,
      galeria: [],
    },
  ]);
  const [paginaactual, guardarPaginaActual] = useState(1);
  const [Editar, SetEditar] = useState({ Estado: true, id: "" });
  const [totalpaginas, guardarTotalPaginas] = useState(5);
  const [pathImage, setPathImage] = useState([
    "/img/card-imagenes.png",
    "/img/card-imagenes.png",
    "/img/card-imagenes.png",
    "/img/card-imagenes.png",
    "/img/card-imagenes.png",
    "/img/card-imagenes.png",
    "/img/card-imagenes.png",
    "/img/card-imagenes.png",
    "/img/card-imagenes.png",
  ]);

  useEffect(() => {
    const consultarApi = async () => {
      //if(busqueda === '' ) return;
      if (false) return;
      //const imagenesPorPagina = 30;
      //const key = '1732750-d45b5378879d1e877cd1d35a6';
      //const url = `https://pixabay.com/api/?key=${key}&q=${busqueda}&per_page=${imagenesPorPagina}&page=${paginaactual}`;

      ///const respuesta = await fetch(url);
      //const resultado = await respuesta.json();
      //console.log(resultado);
      const usuarioAutenticado = async () => {
        try {
          const respuesta = await clienteAxios.get("/api/casas");

          //console.log("GET, CASAS", JSON.stringify(respuesta.data[0]))
          if (imagenes2["Cambio"]) {
            guardarImagenes2({ Cambio: false, Lista: respuesta.data });
          }
          //console.log(imagenes2["Lista"].length, "Tamaño")
          //console.log(imagenes2["Lista"][imagenes2["Lista"].length - 1]["codigo"])
          guardarIncrement(
            imagenes2["Lista"][imagenes2["Lista"].length - 1]["codigo"] + 1
          );
        } catch (error) {
          // console.log("**********")
        }
      };
      if (imagenes2["Cambio"]) {
        usuarioAutenticado();
      }

      const resultado2 = {
        hits: [
          {
            id: 694730,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL:
              "https://cdn.pixabay.com/photo/2015/03/27/13/16/cat-694730_150.jpg",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694731,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694732,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694733,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694734,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694735,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694736,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694737,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694738,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694739,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
          {
            id: 694740,
            pageURL:
              "https://pixabay.com/photos/cat-silhouette-cats-silhouette-694730/",
            type: "photo",
            tags: "cat, silhouette, cats silhouette",
            previewURL: "/img/Casa2.png",
            previewWidth: 150,
            previewHeight: 99,
            webformatURL:
              "https://pixabay.com/get/50e9d1444952b10ff3d8992cc62f347c123adef85257704875297cd3974d_640.jpg",
            webformatWidth: 640,
            webformatHeight: 426,
            largeImageURL:
              "https://pixabay.com/get/50e9d1444952b108f5d084609629307d1738dbe54e507748742f7dd3904dc5_1280.jpg",
            imageWidth: 3235,
            imageHeight: 2157,
            imageSize: 546452,
            views: 301617,
            downloads: 134157,
            favorites: 986,
            likes: 1139,
            comments: 127,
            user_id: 887962,
            user: "ClaudiaWollesen",
            userImageURL:
              "https://cdn.pixabay.com/user/2019/12/22/16-48-03-254_250x250.jpg",
          },
        ],

        total: 2020,
        totalHits: 50,
      };
      //console.log("************")
      //console.log(resultado2.hits)
      //console.log(resultado.hits);

      const detalles2 = {
        detalle: [
          { id: 1, nombre: "Casa 1", precio: 500000, img: "/img/Casa2.png" },
        ],
      };
      //guardarDetalle(detalles2.detalle)
      //guardarImagenes(resultado.hits);
      if (imagenes["Cambio"]) {
        guardarImagenes({ Cambio: false, Lista: resultado2.hits });
      }

      // calcular el total de paginas
      //const calcularTotalPaginas = Math.ceil(resultado2.totalHits / imagenesPorPagina );
      //guardarTotalPaginas(calcularTotalPaginas);

      // Mover la pantalla hacia arriba
      //const jumbotron = document.querySelector('.jumbotron');
      //jumbotron.scrollIntoView({ behavior: 'smooth' })
    };
    consultarApi();
  });

  //console.log(Detalles);
  //console.log(localStorage.getItem('token')+"**********");
  // Revisar si tenemos un token
  const token = localStorage.getItem("token");
  if (token) {
    tokenAuth(token);
  }

  return (
    <Fragment>
      <Router>
        <AlertaState>
          <AuthState>
            <Header
              headers={headers}
              cambiarheader={cambiarheader}
              guardarImagenes2={guardarImagenes2}
            />
            <Switch>
              <Route
                exact
                path="/login"
                render={() => (
                  <Login headers={headers} cambiarheader={cambiarheader} />
                )}
              />

              <Route
                exact
                path="/login2"
                render={() => (
                  <Login2 headers={headers} cambiarheader={cambiarheader} />
                )}
              />

              <Route
                exact
                path="/"
                render={() => (
                  <Home headers={headers} cambiarheader={cambiarheader} />
                )}
              />

              <Route
                exact
                path="/Inmuebles"
                render={() => (
                  <Inmueble
                    imagenes={imagenes}
                    imagenes2={imagenes2}
                    guardarImagenes2={guardarImagenes2}
                    paginaactual={paginaactual}
                    totalpaginas={totalpaginas}
                    guardarPaginaActual={guardarPaginaActual}
                    guardarDetalle={guardarDetalle}
                    Detalles={Detalles}
                    headers={headers}
                    cambiarheader={cambiarheader}
                    apiUrlState={apiUrlState}
                  />
                )}
              />
              <Route
                exact
                path="/Inmuebles/:Tipo"
                render={() => (
                  <Inmueble
                    imagenes={imagenes}
                    imagenes2={imagenes2}
                    guardarImagenes2={guardarImagenes2}
                    paginaactual={paginaactual}
                    totalpaginas={totalpaginas}
                    guardarPaginaActual={guardarPaginaActual}
                    guardarDetalle={guardarDetalle}
                    Detalles={Detalles}
                    headers={headers}
                    cambiarheader={cambiarheader}
                    apiUrlState={apiUrlState}
                  />
                )}
              />

              <Route
                exact
                path="/Nosotros"
                render={() => (
                  <Nosotros headers={headers} cambiarheader={cambiarheader} />
                )}
              />

              <Route
                exact
                path="/Contacto"
                render={() => (
                  <Contacto headers={headers} cambiarheader={cambiarheader} />
                )}
              />

              <Route
                exact
                path="/Detalle/:codigo"
                render={() => (
                  <Detalle
                    Detalles={Detalles}
                    apiUrlState={apiUrlState}
                    guardarDetalle={guardarDetalle}
                    headers={headers}
                    cambiarheader={cambiarheader}
                  />
                )}
              />

              <RutaPrivada
                exact
                path="/VerEditar"
                component={() => (
                  <VerEditar
                    Detalles={Detalles}
                    headers={headers}
                    cambiarheader={cambiarheader}
                    Editar={Editar}
                    SetEditar={SetEditar}
                    apiUrlState={apiUrlState}
                  />
                )}
              />

              <RutaPrivada
                exact
                path="/Insertar"
                component={() => (
                  <Insertar
                    Detalles={Detalles}
                    headers={headers}
                    cambiarheader={cambiarheader}
                    Increment={Increment}
                    guardarIncrement={guardarIncrement}
                    cont={cont}
                    guardarcont={guardarcont}
                    pathImage={pathImage}
                    setPathImage={setPathImage}
                    apiUrlState={apiUrlState}
                  />
                )}
              />
              <RutaPrivada
                exact
                path="/Editar"
                component={() => (
                  <Editarcasa
                    Detalles={Detalles}
                    headers={headers}
                    cambiarheader={cambiarheader}
                    Increment={Increment}
                    guardarIncrement={guardarIncrement}
                    cont={cont}
                    guardarcont={guardarcont}
                    pathImage={pathImage}
                    setPathImage={setPathImage}
                    Editar={Editar}
                    SetEditar={SetEditar}
                    apiUrlState={apiUrlState}
                  />
                )}
              />

              <RutaPrivada
                exact
                path="/Menu"
                component={() => (
                  <Menu
                    Detalles={Detalles}
                    headers={headers}
                    cambiarheader={cambiarheader}
                  />
                )}
              />
            </Switch>
            <Footer />
          </AuthState>
        </AlertaState>
      </Router>
    </Fragment>
  );
}
/* */
export default App;
