import axios from 'axios'
//process.env.REACT_APP_BACKEND_URL


class UploadService { 

    getImages() {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/download`)
    }

    sendImages(name, file) {
        const form = new FormData()
        form.append('name', name)
        form.append('file', file, 'form-data')
       //console.log(name, "upload_function")

        //return axios.post(`${process.env.REACT_APP_BACKEND_URL}/upload`, form)
        return axios.post("https://api.aldiainmobiliaria.com/upload", form)
    }
}

export default new UploadService()