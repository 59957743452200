
import React from 'react';
import Imagen from './Imagen';

const ListadoImagenes = ({imagenes2, imagenes,guardarDetalle,Detalles,apiUrlState}) => {
    
    //console.log(imagenes)
    const Detallescasa = () =>{
        var filtro = 
            {
                "Ciudad" : []
            }

        var aux = []
        
        imagenes2["Lista"].map(imagen => (
            //console.log("MAP",imagen["ciudad"]),
            aux.push(imagen["ciudad"])
            

        ))
        let result = aux.filter((item,index)=>{
            return aux.indexOf(item) === index;
          })

          filtro["Ciudad"].push(result)
       //console.log(JSON.stringify(filtro) )
       //console.log(result)
    }
    Detallescasa()

    return ( 
        <div className=" align-self-center row">
            {/*imagenes["Lista"].map(imagen => (
                <Imagen 
                    key={imagen.id}
                    imagen={imagen}
                    guardarDetalle={guardarDetalle}
                    Detalles={Detalles}
                />
            ))*/}
            {imagenes2["Lista"].map(imagen => (
                //console.log("Map Get Casas: ", imagen)
                <Imagen 
                    key={imagen.codigo}
                    imagen={imagen}
                    guardarDetalle={guardarDetalle}
                    Detalles={Detalles}
                    imagenes2={imagenes2}
                    apiUrlState={apiUrlState}
                />
            ))}  
        </div>
        
    );
}
 
export default ListadoImagenes;