import React, { useContext, useEffect } from 'react';
import { Route, Redirect,Link } from 'react-router-dom';
import AuthContext from '../../context/autenticacion/authContext';


const RutaPrivada = ({ component: Component, ...props  }) => {

    const authContext = useContext(AuthContext);
    const { autenticado, cargando, usuarioAutenticado } = authContext;

    useEffect(() => {
        usuarioAutenticado();
        // eslint-disable-next-line
    }, []);

    return ( 
        <Route { ...props } render={ props => !autenticado && !cargando ?  (
            {/*<Redirect to="/Login" />*/},
            window.location.href = '/Login'
            
        )  : (
            <Component {...props} />
        ) } />

     );
}
 
export default RutaPrivada;