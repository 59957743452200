import AuthContext from "../../context/autenticacion/authContext";
import React, { useState, useEffect, Fragment, useContext } from "react";
import Upload from "./Upload";
import "./admin.css";
import clienteAxios from "../../config/Axios";
const Detalle = ({
  Detalles,
  cambiarheader,
  headers,
  Increment,
  guardarIncrement,
  cont,
  guardarcont,
  pathImage,
  setPathImage,
  apiUrlState
}) => {
  const [longitudFT, setlongitudFT] = useState(0);
  const [longitudFD, setlongitudFD] = useState(0);
  const [Imagenes, setImagenes] = useState([]);
  const [Dep, GetDep] = useState([]);
  const [cuid, GetCiud] = useState([]);
  const [inputdirec, Getinputdirec] = useState({via: " ",Nro: "",Letra: " ", Sector: " ", Cruce: " ", NroPlaca: " ", LetraPalaca: " ", NroPlaca2: " " });

 //console.log("longitud", longitudFT);
 //console.log("longitudFD", longitudFD);
 //console.log("inputdirec", inputdirec);
  const style = {
    marginTop: "10vh",
    marginLeft: "15vw",
    paddingRight: "15vw",
  };

  const Imagen = {
    height: "35px",
    width: "35px",
    marginTop: "12px",
  };
  const [CrearCasas, setCrearCasas] = useState({
    titulo: "",
    precio: "",
    tipo: "",
    habitaciones: 0,
    nombre: "",
    garaje: 0,
    area: {
      ancho: 0,
      largo: 0,
    },
    baños: 0,
    estrato: 0,
    descripcion: "",
    barrio: "",
    ciudad: "",
    departamento:"",
    telefono: "",
    direccion:"",
    adicional: {
      general: {
        conjunto: false,
        vigilancia: false,
        unaPlanta: false,
        unifamiliar: false,
        bifamiliar: false,
        cocina: false,
        bañera: false,
        patio: false,
        citofono: false,
        garaje: false,
        piscina: false,
        sauna: false,
        jardin: false,
        terraza: false,
      },
      comunes: {
        piscina: false,
        canchas: false,
        zverde: false,
        zinfantil: false,
        zpet: false,
      },
      sector: {
        comercial: false,
        colegio: false,
        supermercados: false,
        transporte: false,
        parque: false,
        principal: false,
      },
    },
    Images: [],
  });

  /*const authContext = useContext(AuthContext);
    const { usuarioAutenticado } = authContext;*/
  //console.log("habitaciones**", CrearCasas)
 //console.log("CrearCasas-Total", CrearCasas);
  const Filtros_Departamentos = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/casas/Departamento");

     //console.log("Filtros_Departamentos", JSON.stringify(respuesta.data));
      GetDep(respuesta.data);
    } catch (error) {
     //console.log("**********");
    }
  };
  // Filtros_Departamentos()
  useEffect(() => {
    if (headers["display"] === "flex") {
      cambiarheader({
        position: "relative",
        color: "#ffff",
        display: "inline",
        Login: "/img/Header-Aldia2.png",
      });
    }

    if (isNaN(longitudFD)) {
      setlongitudFD("");
    }
    if (isNaN(longitudFT)) {
      setlongitudFT("");
    }
    //usuarioAutenticado();
    // eslint-disable-next-line
    Filtros_Departamentos();
  }, []);

  const adicionar = (e, categoria) => {
    /* Adicion de input Area de Fondo*/
    if (categoria == "longitudFT") {
      var mas = parseInt(longitudFT) + 1;
      var area = CrearCasas.area;
      area.largo = mas;
     //console.log("area", area);

      setCrearCasas({
        ...CrearCasas,
        area: area,
      });
      setlongitudFT(mas);
    }
    if (categoria == "longitudFD") {
      var mas = parseInt(longitudFD) + 1;
      var area = CrearCasas.area;
      area.ancho = mas;
     //console.log("area", area);

      setCrearCasas({
        ...CrearCasas,
        area: area,
      });
      setlongitudFD(mas);
    }

    if (categoria == "Habitaciones") {
     //console.log("++", e.target.value);
      var menos3 = parseInt(CrearCasas.habitaciones) + 1;

      setCrearCasas({
        ...CrearCasas,
        habitaciones: menos3,
      });
     //console.log("CrearCasas.area.ancho suma**", CrearCasas.habitaciones);
    }
    if (categoria == "Baños") {
     //console.log("++", e.target.value);
      var menos3 = parseInt(CrearCasas.baños) + 1;

      setCrearCasas({
        ...CrearCasas,
        baños: menos3,
      });
    }
    if (categoria == "Garaje") {
     //console.log("++", e.target.value);
      var menos3 = parseInt(CrearCasas.garaje) + 1;

      setCrearCasas({
        ...CrearCasas,
        garaje: menos3,
      });
    }
    if (categoria == "Estrato") {
     //console.log("++", e.target.value);
      var menos3 = parseInt(CrearCasas.estrato) + 1;

      setCrearCasas({
        ...CrearCasas,
        estrato: menos3,
      });
    }
  };

  /* Atualizar inputs */
  const actualizar = (e, categoria) => {
    e.preventDefault();

   //console.log("--", e.target.value);

    if (categoria == "longitudFD") {
      var menos2 = parseInt(e.target.value);
      var area = CrearCasas.area;
      area.ancho = menos2;
     //console.log("area", area);

      setCrearCasas({
        ...CrearCasas,
        area: area,
      });

      setlongitudFD(menos2);
     //console.log("CrearCasas.area.ancho", CrearCasas.area);
    }
    if (categoria == "Titulo") {
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        titulo: menos2,
      });
    }
    if (categoria == "Baños") {
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        baños: menos2,
      });
    }

    if (categoria == "Descripcion") {
      var menos2 = e.target.value;
      setCrearCasas({
        ...CrearCasas,
        descripcion: menos2,
      });
    }

    if (categoria == "Precio") {
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        precio: parseInt(menos2),
      });
    }
    if (categoria == "Tipo") {
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        tipo: menos2,
      });
    }
    if (categoria == "Barrio") {
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        barrio: menos2,
      });
    }
    if (categoria == "Direccion") {
     //console.log("++", e.target.value);

      var menos2 = e.target.value;
      //Nro: " ",Letra: " ", Sector: " ", Cruce: " ", NroPlaca: " ", LetraPalaca: " ", NroPlaca2: " "
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: menos2,
      });
    }
    if (categoria == "via") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.via = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "Nro") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.Nro = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "Letra") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.Letra = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "Sector") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.Sector = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "NroPlaca") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.NroPlaca = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "Cruce") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.Cruce = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "NroPlaca") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.NroPlaca = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "NroPlaca2") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.NroPlaca2 = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "LetraPalaca") {
     //console.log("++", e.target.value);
      var actual = inputdirec
      var menos2 = e.target.value;
      actual.LetraPalaca = e.target.value
     //console.log("actual",actual)
      Getinputdirec(actual);
      var conctdirec = inputdirec.via + " " +  inputdirec.Nro +" " + inputdirec.Letra +" " + inputdirec.Sector +" " + inputdirec.Cruce +" #" + inputdirec.NroPlaca +" "+ inputdirec.LetraPalaca +" - "+  inputdirec.NroPlaca2
      setCrearCasas({
        ...CrearCasas,
        direccion: conctdirec,
      });
    }
    if (categoria == "Telefono") {
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        telefono: menos2,
      });
    }
    if (categoria == "Nombre") {
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        nombre: menos2,
      });
    }
    if (categoria == "Ciudad") {
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        ciudad: menos2,
      });
    }
    if (categoria == "Departamento") {
      Dep.map(function (Depa) {
        if (Depa["departamento"] == e.target.value) {
          GetCiud(Depa["ciudades"]);
         //console.log("entra:", Depa["ciudades"]);
        } else {
         //console.log("NO entra");
        }
      });
     //console.log("++", e.target.value);
      var menos2 = e.target.value;

      setCrearCasas({
        ...CrearCasas,
        departamento: menos2,
      });

      //GetCiud(Dep["ciudades"])
     //console.log("Dep[ciudades]", Dep.ciudades);
    }

    if (categoria == "longitudFT") {
     //console.log("++", e.target.value);
      var menos2 = parseInt(e.target.value);
      var area = CrearCasas.area;
      area.largo = menos2;
     //console.log("area", area);

      setCrearCasas({
        ...CrearCasas,
        area: area,
      });

      setlongitudFT(menos2);
     //console.log("CrearCasas.area.ancho", CrearCasas.area);
    }
    if (categoria == "Habitaciones") {
     //console.log("++", e.target.value);
      var menos2 = parseInt(e.target.value);

      setCrearCasas({
        ...CrearCasas,
        habitaciones: menos2,
      });
    }

    if (categoria == "Garaje") {
     //console.log("++", e.target.value);
      var menos2 = parseInt(e.target.value);

      setCrearCasas({
        ...CrearCasas,
        garaje: menos2,
      });
    }
    if (categoria == "Estrato") {
     //console.log("++", e.target.value);
      var menos2 = parseInt(e.target.value);
      setCrearCasas({
        ...CrearCasas,
        estrato: menos2,
      });
    }
  };

  const restar = (e, categoria) => {
    if (categoria == "longitudFT") {
      var menos1 = parseInt(longitudFT) - 1;
      var area = CrearCasas.area;
      area.largo = menos1;
     //console.log("area", area);

      setCrearCasas({
        ...CrearCasas,
        area: area,
      });
      setlongitudFT(menos1);
     //console.log("CrearCasas.area.ancho", CrearCasas.area);
    }
    if (categoria == "longitudFD") {
      var menos2 = parseInt(longitudFD) - 1;
      var area = CrearCasas.area;
      area.ancho = menos2;
     //console.log("area", area);

      setCrearCasas({
        ...CrearCasas,
        area: area,
      });
      setlongitudFD(menos2);
     //console.log("CrearCasas.area.ancho", CrearCasas.area);
    }

    if (categoria == "Habitaciones") {
     //console.log("++", e.target.value);
      var menos3 = parseInt(CrearCasas.habitaciones) - 1;

      setCrearCasas({
        ...CrearCasas,
        habitaciones: menos3,
      });
    }
    if (categoria == "Baños") {
     //console.log("++", e.target.value);
      var menos3 = parseInt(CrearCasas.baños) - 1;

      setCrearCasas({
        ...CrearCasas,
        baños: menos3,
      });
    }
    if (categoria == "Garaje") {
     //console.log("++", e.target.value);
      var menos3 = parseInt(CrearCasas.garaje) - 1;

      setCrearCasas({
        ...CrearCasas,
        garaje: menos3,
      });
    }
    if (categoria == "Estrato") {
     //console.log("++", e.target.value);
      var menos3 = parseInt(CrearCasas.estrato) - 1;

      setCrearCasas({
        ...CrearCasas,
        estrato: menos3,
      });
    }
    /*setCrearCasas({
        ...CrearCasas,
        area : {"area" : longitudFT,
                "largo" : longitudFD,}
      })*/
  };
  const sendImage = () => {
    try {
      const respuesta = clienteAxios.post("/api/casas", CrearCasas);
    } catch (error) {
     //console.log("Error al crear casa");
    }
  };
  const checkbox = (e, categoria) => {
    // !State
    if (categoria == "Conjunto") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.conjunto = !value.general.conjunto;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "vigilancia") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.vigilancia = !value.general.vigilancia;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Unifamiliar") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.unifamiliar = !value.general.unifamiliar;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Bifamiliar") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.bifamiliar = !value.general.bifamiliar;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Garaje") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.garaje = !value.general.garaje;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "citofono") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.citofono = !value.general.citofono;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Cocina") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.cocina = !value.general.cocina;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Bañera") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.bañera = !value.general.bañera;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Jardin") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.jardin = !value.general.jardin;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Patio") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.patio = !value.general.patio;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Piscina") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.piscina = !value.general.piscina;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Planta") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.unaPlanta = !value.general.unaPlanta;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Terraza") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.general.terraza = !value.general.terraza;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "PiscinaC") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.comunes.piscina = !value.comunes.piscina;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Canchas") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.comunes.canchas = !value.comunes.canchas;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "ZonasV") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.comunes.zverde = !value.comunes.zverde;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "ZonaI") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.comunes.zinfantil = !value.comunes.zinfantil;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "ZonaP") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.comunes.zpet = !value.comunes.zpet;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "supermercados") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.sector.supermercados = !value.sector.supermercados;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Colegios") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.sector.colegio = !value.sector.colegio;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Transporte") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.sector.transporte = !value.sector.transporte;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Parques") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.sector.parque = !value.sector.parque;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
    if (categoria == "Restaurantes") {
      var value = CrearCasas.adicional;
      //console.log("value.conjunto", CrearCasas.adicional.general.conjunto)
      value.sector.comercial = !value.sector.comercial;
      //console.log("value.conjunto", value)

      setCrearCasas({
        ...CrearCasas,
        adicional: value,
      });
    }
  };
 //console.log("CrearCasas.area.ancho", CrearCasas.area);

  /*
      setCrearCasas({
          ...CrearCasas,
          area : {ancho: longitudFD,
                  largo: longitudFT}
        })
      
      */
  //console.log("Detalle: " + JSON.stringify(Detalles.detalle[0].img))
  //const image = Detalles.detalle[0].img
  //console.log("****: " + image)
  return (
    <div className="container-fluid" style={{ paddingTop: "5vh" }}>
      <div className="row">
        <div className="col-sm-6" style={{paddingBottom: "50px"}}>
          <div style={{ color: "#500f0f" }}>
            <ul className="list-group list-group-flush">
              <li className="list-group-item border-0">
                <input
                  className="form-control"
                  value={CrearCasas.titulo}
                  onChange={(e) => actualizar(e, "Titulo")}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  type="text"
                  placeholder="Titulo"
                />
              </li>

              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <input
                  type="text"
                  className="form-control"
                  value={CrearCasas.precio}
                  onChange={(e) => actualizar(e, "Precio")}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Valor"
                />
              </li>

              {/* Categorio Tipo Inmueble */}

              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <div className="form-group text-center">
                  <select
                    placeholder="hola"
                    className="form-control"
                    value={CrearCasas.tipo}
                    onChange={(e) => actualizar(e, "Tipo")}
                    id="exampleFormControlSelect3"
                  >
                    <option defaultValue style={{color:"#4d141fab"}}>Tipo de Inmueble</option>
                    <option style={{color:"#4d141fab"}}>Apartamento</option>
                    <option style={{color:"#4d141fab"}}>Casa</option>
                    <option style={{color:"#4d141fab"}}>Lote</option>
                    <option style={{color:"#4d141fab"}}>Apartaestudio</option>
                    <option style={{color:"#4d141fab"}}>Casa Campestre</option>
                    <option style={{color:"#4d141fab"}}>Edificio</option>
                  </select>
                </div>
              </li>

              {/* Categorio Precio Inmueble */}

              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />

              {/* Muestra */}
              <li className="list-group-item border-0">
                <div className="row">
                  <div
                    style={{
                      width: "200px",
                      display: "inline-flex",
                      marginLeft: "30px",
                    }}
                  >
                    <img src="/img/Icono-baños.png" style={Imagen} />
                    <div style={{ marginLeft: "10px", textAlign: "center" }}>
                      <div>
                        <label
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            fontWeight: "bold ",
                          }}
                        >
                          BAÑOS
                        </label>
                      </div>
                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 30,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        onClick={(e) => restar(e, "Baños")}
                      >
                        -
                      </button>

                      <input
                        className="inputne"
                        value={CrearCasas.baños}
                        onChange={(e) => actualizar(e, "Baños")}
                        style={{
                          width: "50px",
                          border: "0px",
                          textAlign: "center",
                        }}
                      />

                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        data-hint="Cantidad Maxima"
                        onClick={(e) => adicionar(e, "Baños")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "200px",
                      display: "inline-flex",
                      marginLeft: "30px",
                    }}
                  >
                    <img src="/img/Icono-garaje.png" style={Imagen} />
                    <div style={{ marginLeft: "10px", textAlign: "center" }}>
                      <div>
                        <label
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            fontWeight: "bold ",
                          }}
                        >
                          GARAJE
                        </label>
                      </div>
                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 30,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        onClick={(e) => restar(e, "Garaje")}
                      >
                        -
                      </button>

                      <input
                        className="inputne"
                        value={CrearCasas.garaje}
                        onChange={(e) => actualizar(e, "Garaje")}
                        style={{
                          width: "50px",
                          border: "0px",
                          textAlign: "center",
                        }}
                      />

                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        data-hint="Cantidad Maxima"
                        onClick={(e) => adicionar(e, "Garaje")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "200px",
                      display: "inline-flex",
                      marginLeft: "30px",
                    }}
                  >
                    <img src="/img/Icono-estrato.png" style={Imagen} />
                    <div style={{ marginLeft: "10px", textAlign: "center" }}>
                      <div>
                        <label
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            fontWeight: "bold ",
                          }}
                        >
                          ESTRATO
                        </label>
                      </div>
                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 30,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        onClick={(e) => restar(e, "Estrato")}
                      >
                        -
                      </button>

                      <input
                        className="inputne"
                        value={CrearCasas.estrato}
                        onChange={(e) => actualizar(e, "Estrato")}
                        style={{
                          width: "50px",
                          border: "0px",
                          textAlign: "center",
                        }}
                      />

                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        data-hint="Cantidad Maxima"
                        onClick={(e) => adicionar(e, "Estrato")}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "200px",
                      display: "inline-flex",
                      marginLeft: "30px",
                    }}
                  >
                    <img src="/img/Icono-habitaciones.png" style={Imagen} />
                    <div style={{ marginLeft: "10px", textAlign: "center" }}>
                      <div>
                        <label
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            fontWeight: "bold ",
                          }}
                        >
                          HABITACIONES
                        </label>
                      </div>
                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 30,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        onClick={(e) => restar(e, "Habitaciones")}
                      >
                        -
                      </button>

                      <input
                        className="inputne"
                        value={parseInt(CrearCasas.habitaciones)}
                        onChange={(e) => actualizar(e, "Habitaciones")}
                        style={{
                          width: "50px",
                          border: "0px",
                          textAlign: "center",
                        }}
                      />

                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        data-hint="Cantidad Maxima"
                        onClick={(e) => adicionar(e, "Habitaciones")}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  {/* FRENTE && FONDO */}
                  <div
                    style={{
                      width: "330px",
                      display: "inline-flex",
                      marginLeft: "30px",
                    }}
                  >
                    <img style={Imagen} src="/img/Icono-area.png" />
                    <div style={{ marginLeft: "10px", textAlign: "center" }}>
                      <div>
                        <label
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            fontWeight: "bold ",
                          }}
                        >
                          FRENTE
                        </label>
                      </div>
                      <div>
                        <button
                          className="SinDecoracion"
                          style={{
                            fontSize: 25,
                            marginLeft: 30,
                            marginRight: 10,
                            marginBottom: 10,
                          }}
                          onClick={(e) => restar(e, "longitudFT")}
                        >
                          -
                        </button>

                        <input
                          className="inputne"
                          value={longitudFT}
                          onChange={(e) => actualizar(e, "longitudFT")}
                          style={{
                            width: "50px",
                            border: "0px",
                            textAlign: "center",
                          }}
                        />

                        <button
                          className="SinDecoracion"
                          style={{
                            fontSize: 25,
                            marginLeft: 10,
                            marginRight: 10,
                            marginBottom: 10,
                          }}
                          data-hint="Cantidad Maxima"
                          onClick={(e) => adicionar(e, "longitudFT")}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div style={{ marginLeft: "10px", textAlign: "center" }}>
                      <div>
                        <label
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            fontWeight: "bold ",
                          }}
                        >
                          FONDO
                        </label>
                      </div>
                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 30,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        onClick={(e) => restar(e, "longitudFD")}
                      >
                        -
                      </button>

                      <input
                        className="inputne"
                        value={longitudFD}
                        onChange={(e) => actualizar(e, "longitudFD")}
                        style={{
                          width: "50px",
                          border: "0px",
                          textAlign: "center",
                        }}
                      />

                      <button
                        className="SinDecoracion"
                        style={{
                          fontSize: 25,
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                        data-hint="Cantidad Maxima"
                        onClick={(e) => adicionar(e, "longitudFD")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              {/* Descripcion */}

              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <p style={{margin:"0px", fontSize: "25px", fontWeight: "bold "}}>Descripción</p>
              </li>

              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <textarea
                  className="form-control"
                  value={CrearCasas.descripcion}
                  onChange={(e) => actualizar(e, "Descripcion")}
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </li>

              {/* Depatamento */}
              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <select
                  className="form-control"
                  onChange={(e) => actualizar(e, "Departamento")}
                  id="exampleInputEmail21"
                  aria-describedby="emailHelp"
                >
                  {Dep.map((Depar) => (
                    //console.log("Map Get Casas: ", imagen)

                    <option key={Depar["departamento"]} style={{color:"#4d141fab"}}>
                      {Depar["departamento"]}
                    </option>
                  ))}
                </select>
              </li>

              {/* Ciudad */}
              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <select
                  className="form-control"
                  onChange={(e) => actualizar(e, "Ciudad")}
                  id="exampleInputEmail22"
                  aria-describedby="emailHelp"
                >
                  {cuid.map((cuidad) => (
                    //console.log("Map Get Casas: ", imagen)

                    <option key={cuidad}>{cuidad}</option>
                  ))}
                </select>
              </li>

              {/* Barrio */}

              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                  
                  <div className="row">
                  <div
                    style={{
                      maxWidth: "800px",
                      display: "inline-flex",
                      marginLeft: "30px",
                      
                    }}
                  >
                    <img style={Imagen} src="/img/Icono-barrio.png" />
                    <div style={{ marginLeft: "10px", textAlign: "center", marginTop:"2px"}}>
                        <input
                          className="form-control"
                          value={CrearCasas.barrio}
                          onChange={(e) => actualizar(e, "Barrio")}
                          id="exampleInputEmail12"
                          aria-describedby="emailHelp"
                          type="text"
                          placeholder="Barrio/Zona"
                          
                        />
                    </div>
                    </div>
                    </div>
              </li>
              {/* Direccion */}

              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <div className="row">
                  <div className="col-sm">
                    <input
                      className="form-control"
                      type="text"
                      value={CrearCasas.direccion}
                      onChange={(e) => actualizar(e, "Direccion")}
                      id="exampleInputEmail13"
                      aria-describedby="emailHelp"
                      placeholder="Direccion"
                      
                    />
                  </div>
                  {/*
                  <div className="col-sm">
                                      
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" 
                      style={{backgroundColor:"#500f0f", color: "#ffff", borderColor: "#500f0f", fontWeight: "bold"}}>
                      Ingresar
                    </button>
                    <div
                      className="modal fade"
                      id="exampleModalCenter"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5>Direccion</h5>
                          </div>
                          <div className="modal-body">
                            <div className="row form-group">
                              <div className="form-group col-12">
                                <label
                                  for="PrevizualizacionLabel"
                                  className="grey-text font-weight-light"
                                >
                                  Previzualización</label>
                                <input
                                  type="text"
                                  id="PrevizualizacionLabel"
                                  className="form-control"
                                  value={ CrearCasas.direccion}
                                  disabled
                                  onChange={(e) => actualizar(e, "Direccion")}
                                />
                              </div>
                              <div className="form-group col-12">
                                <label
                                  for="ViaInicio"
                                  className="grey-text font-weight-light"
                                >
                                  Via </label>
                                <select
                                  id="ViaInicio"
                                  className="form-control"
                                  value={ inputdirec.via }
                                  onChange={(e) => actualizar(e, "via")}
                                  
                                >
                                  <option value="">Seleccione</option>
                                  <option value="AV">Avenida</option>
                                  <option value="CL">Calle</option>
                                  <option value="CR">Carrera</option>
                                  <option value="DA">Diagonal</option>
                                  <option value="PA">Pasaje</option>
                                  <option value="TR">Transversal</option>
                                  <option value="UR">Urbanizacion</option>
                                  <option value="MZ">Manzana</option>
                                  <option value="CS">Casa</option>
                                </select>
                              </div>
                              <div className="form-group col-6">
                                <label
                                  for="NumeroVia"
                                  className="grey-text font-weight-light"
                                >
                                  Nro </label>
                                <input
                                  type="number"
                                  id="NumeroVia"
                                  className="form-control"
                                  min="0"
                                  max="99"
                                  pattern="^[0-99]"
                                  value={ inputdirec.Nro }
                                  onChange={(e) => actualizar(e, "Nro")}
                                  
                                />
                              </div>
                              <div className="form-group col-6">
                                <label
                                  for="LetraVia"
                                  className="grey-text font-weight-light"
                                >
                                  Letra</label>
                                <select
                                  id="LetraVia"
                                  className="form-control"
                                  maxLength="2"
                                  value={inputdirec.Letra }
                                  onChange={(e) => actualizar(e, "Letra")}
                                >
                                  <option value="">Seleccione</option>
                                  <option value="A">A</option>
                                  <option value="B">B</option>
                                  <option value="C">C</option>
                                  <option value="D">D</option>
                                  <option value="E">E</option>
                                  <option value="F">F</option>
                                  <option value="G">G</option>
                                  <option value="H">H</option>
                                </select>
                              </div>
                              <div className="form-group col-6">
                                <label
                                  for="SectorDireccion"
                                  className="grey-text font-weight-light"
                                >
                                  Sector</label>
                                <select
                                  id="SectorDireccion"
                                  className="form-control"
                                  value={inputdirec.Sector }
                                  onChange={(e) => actualizar(e, "Sector")}
                                  
                                >
                                  <option value="">Seleccione</option>
                                  <option value="Norte">Norte</option>
                                  <option value="Oeste">Oeste</option>
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-12">
                                <label
                                  for="CruceDireccion"
                                  className="grey-text font-weight-light"
                                >
                                  Cruce</label>
                                <select
                                  id="CruceDireccion"
                                  className="form-control"
                                  value={inputdirec.Cruce }
                                  onChange={(e) => actualizar(e, "Cruce")}
                                  
                                >
                                  <option value="">Seleccione</option>
                                  <option value="AV">Avenida</option>
                                  <option value="DA">Diagonal</option>
                                  <option value="KL">Kilometro</option>
                                  <option value="TR">Transversal</option>
                                </select>
                              </div>
                              <div className="form-group col-6">
                                <label
                                  for="NumeroPlaca1"
                                  className="grey-text font-weight-light"
                                >
                                  Nro placa</label
                                >
                                <input
                                  id="NumeroPlaca1"
                                  type="number"
                                  className="form-control"
                                  min="0"
                                  max="99"
                                  pattern="^[0-99]"
                                  value={inputdirec.NroPlaca }
                                  onChange={(e) => actualizar(e, "NroPlaca")}
                                  
                                />
                              </div>
                              <div className="form-group col-6">
                                <label
                                  for="LetraPlaca"
                                  className="grey-text font-weight-light"
                                >
                                  Letra placa</label
                                >
                                <select
                                  id="LetraPlaca"
                                  className="form-control"
                                  maxLength="2"
                                  value={inputdirec.LetraPalaca }
                                  onChange={(e) => actualizar(e, "LetraPalaca")}
                                  
                                >
                                  <option value="">Seleccione</option>
                                  <option value="A">A</option>
                                  <option value="B">B</option>
                                  <option value="C">C</option>
                                  <option value="D">D</option>
                                  <option value="E">E</option>
                                  <option value="F">F</option>
                                  <option value="G">G</option>
                                  <option value="H">H</option>
                                </select>
                              </div>
                              <div className="form-group col-6">
                                <label
                                  for="NumeroPlaca2"
                                  className="grey-text font-weight-light"
                                >
                                  Nro placa</label>
                                <input
                                  id="NumeroPlaca2"
                                  type="number"
                                  className="form-control"
                                  min="0"
                                  max="99"
                                  pattern="^[0-99]"
                                  value={inputdirec.NroPlaca2 }
                                  onChange={(e) => actualizar(e, "NroPlaca2")}
                                  
                                />
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              className="btn btn-primary"
                              data-dismiss="modal">
                              Crear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="modal fade" id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            ...
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>*/}
                  <div className="col-sm">
                    <input
                      className="form-control"
                      type="text"
                      value={CrearCasas.telefono}
                      onChange={(e) => actualizar(e, "Telefono")}
                      id="exampleInputEmailTel"
                      aria-describedby="emailHelp"
                      placeholder="Telefono"
                    />
                  </div>
                  <div className="col-sm">
                    <input
                      className="form-control"
                      type="text"
                      value={CrearCasas.nombre}
                      onChange={(e) => actualizar(e, "Nombre")}
                      id="exampleInputEmailTel"
                      aria-describedby="emailHelp"
                      placeholder="Nombre "
                    />
                  </div>
                </div>
              </li>

              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <p>Caracteristicas adicionales</p>
              </li>
              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              <li className="list-group-item border-0">
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label>General</label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Conjunto")}
                          type="checkbox"
                          value=""
                          id="Conjunto"
                        />
                        <label className="form-check-label" htmlFor="Conjunto">
                          Conjunto cerrado
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "vigilancia")}
                          type="checkbox"
                          value=""
                          id="vigilancia"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="vigilancia"
                        >
                          vigilancia
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Unifamiliar")}
                          type="checkbox"
                          value=""
                          id="Unifamiliar"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Unifamiliar"
                        >
                          Unifamiliar
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Bifamiliar")}
                          type="checkbox"
                          value=""
                          id="Bifamiliar"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Bifamiliar"
                        >
                          Bifamiliar(Doble Renta)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Garaje")}
                          type="checkbox"
                          value=""
                          id="Garaje"
                        />
                        <label className="form-check-label" htmlFor="Garaje">
                          Garaje
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="form-group">
                      <label> </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "citofono")}
                          type="checkbox"
                          value=""
                          id="citofono"
                        />
                        <label className="form-check-label" htmlFor="citofono">
                          citofono
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Cocina")}
                          type="checkbox"
                          value=""
                          id="Cocina Integral"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Cocina Integral"
                        >
                          Cocina Integral
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Bañera")}
                          type="checkbox"
                          value=""
                          id="Bañera"
                        />
                        <label className="form-check-label" htmlFor="Bañera">
                          Bañera
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Patio")}
                          type="checkbox"
                          value=""
                          id="Patio"
                        />
                        <label className="form-check-label" htmlFor="Patio">
                          Patio
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Jardin")}
                          type="checkbox"
                          value=""
                          id="jardin"
                        />
                        <label className="form-check-label" htmlFor="jardin">
                          Jardin
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="form-group">
                      <label> </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Piscina")}
                          type="checkbox"
                          value=""
                          id="Piscina"
                        />
                        <label className="form-check-label" htmlFor="Piscina">
                          Piscina
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Planta")}
                          type="checkbox"
                          value=""
                          id="Planta"
                        />
                        <label className="form-check-label" htmlFor="Planta">
                          Una Planta
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onClick={(e) => checkbox(e, "Terraza")}
                          type="checkbox"
                          value=""
                          id="Terraza"
                        />
                        <label className="form-check-label" htmlFor="Terraza">
                          Terraza
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: "2px solid #500f0f",
                      textAlign: "initial",
                    }}
                  >
                    <div className="col-sm">
                      <div className="form-group">
                        <label>Zonas Comunes</label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "PiscinaC")}
                            type="checkbox"
                            value=""
                            id="PiscinaC"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="PiscinaC"
                          >
                            Piscina Comunal
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "Canchas")}
                            type="checkbox"
                            value=""
                            id="Canchas"
                          />
                          <label className="form-check-label" htmlFor="Canchas">
                            Canchas Deportivas
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "ZonasV")}
                            type="checkbox"
                            value=""
                            id="ZonasV"
                          />
                          <label className="form-check-label" htmlFor="ZonasV">
                            Zonas Verdes
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "ZonaI")}
                            type="checkbox"
                            value=""
                            id="ZonaI"
                          />
                          <label className="form-check-label" htmlFor="ZonaI">
                            Zona Infantil
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "ZonaP")}
                            type="checkbox"
                            value=""
                            id="ZonaP"
                          />
                          <label className="form-check-label" htmlFor="ZonaP">
                            Zona P
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: "2px solid #500f0f",
                      textAlign: "initial",
                    }} bn     
                  >
                    <div className="col-sm">
                      <div className="form-group">
                        <label>Del Sector</label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "supermercados")}
                            type="checkbox"
                            value=""
                            id="supermercados"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="supermercados"
                          >
                            Cerca a Supermercados
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "Colegios")}
                            type="checkbox"
                            value=""
                            id="Colegios"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Colegios"
                          >
                            Cerca a Colegios / Universidades
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "Transporte")}
                            type="checkbox"
                            value=""
                            id="Transporte"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Transporte"
                          >
                            Cerca Transporte Publico
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "Parques")}
                            type="checkbox"
                            value=""
                            id="Parques"
                          />
                          <label className="form-check-label" htmlFor="Parques">
                            Cerca a Parques
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onClick={(e) => checkbox(e, "Restaurantes")}
                            type="checkbox"
                            value=""
                            id="Restaurantes"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Restaurantes"
                          >
                            Cerca a Restaurantes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <hr
                style={{
                  color: "#500f0f",
                  backgroundColor: "#500f0f",
                  height: 1,
                  borderColor: "#500f0f",
                  marginBottom: "0px",
                  marginTop: "10px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
            </ul>
          </div>
        </div>
        <div className="col-sm-6" style={{paddingBottom: "50px"}}>
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <Upload
                  Increment={Increment}
                  guardarIncrement={guardarIncrement}
                  cont={cont}
                  guardarcont={guardarcont}
                  pathImage={pathImage}
                  setPathImage={setPathImage}
                  CrearCasas={CrearCasas}
                  setCrearCasas={setCrearCasas}
                  Imagenes={Imagenes}
                  setImagenes={setImagenes}
                  Tipo={false}
                  apiUrlState={apiUrlState}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detalle;
