import Carrousel from './Carrousel'
import './Detalle.css';
import React, { useState, useEffect, Fragment , useContext} from 'react';
import clienteAxios from '../../config/Axios';
import {Link} from 'react-router-dom';
import { $ }  from 'react-jquery-plugin'
import CarouselDetalle from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { useParams } from "react-router";

const Detalle = ({Detalles,apiUrlState, guardarDetalle,headers,cambiarheader}) => {
  const [ Filtro, GetFiltro] = useState([]);
  const [ Todas, GetTodas] = useState([]);
  const [ idCasa, GetidCasa] = useState({
    detalle: [
      { id: 50, nombre: 'Casa 4', precio: 80000, img: ""}
    ],
    cambio: true,
    galeria: [],
    descripcion: ""
});
    const style = {
        marginTop: "10vh",
        marginLeft: "15vw",
        paddingRight: "15vw",
}
    const Imagenes = {
      height: "35px",
      width: "35px",
      marginTop: "12px"
}
var {codigo} = useParams();
/*console.log("codigo: ", codigo)
console.log("Detalles: ", Detalles)
console.log("idCasa: ", idCasa)*/
const images = idCasa["galeria"].map((size) => ({
  src: `https://api.aldiainmobiliaria.com/${size}`,
  sizes: '(max-width: 200px) 200px, (max-height: 60px) 20px, 100px',
}));
//console.log("*-*Detalle: ",  Detalles)
const formatNumber = (amount) => {
  $('.carousel').on('touchstart', function(event){
    const xClick = event.originalEvent.touches[0].pageX;
    $(this).one('touchmove', function(event){
        const xMove = event.originalEvent.touches[0].pageX;
        const sensitivityInPx = 5;

        if( Math.floor(xClick - xMove) > sensitivityInPx ){
            $(this).carousel('next');
        }
        else if( Math.floor(xClick - xMove) < -sensitivityInPx ){
            $(this).carousel('prev');
        }
    });
    $(this).on('touchend', function(){
        $(this).off('touchmove');
    });
  });
  var temp = amount + '';
  var i = temp.length-3;
  while(i > 0){
      temp = temp.substring(0, i) + '.' + temp.substring(i);
      i-=3;
  }
  return " " + temp
}
    //console.log("Detalle: " + JSON.stringify(Detalles.detalle[0].img))
    //const image = Detalles.detalle[0].img
    //console.log("****: " + image)
    //{"precio": { $lt : 60000000000, $gt : 300000000 } } precio Detalles.detalle[0].precio

    const Detallescasa = (Img, posicion) =>{
        
      //window.location.href = '/Detalle';
      const prueba = {
          detalle: [
            { id: 50, nombre: 'Casa 4', precio: 80000, img: apiUrlState + Img.Images[0]}
          ],
          cambio: true,
          galeria: Img.Images,
          descripcion: Img
      }
      guardarDetalle(prueba);
      GetidCasa(prueba)
      
      //console.log("Detalles: " + JSON.stringify(Img) )
      
  }

const buscar = async (datos) => {
      const respuesta = await clienteAxios.post('/api/casas/Filtrar', {"precio": { $lt : idCasa.descripcion.precio + 100000000, $gt :  idCasa.descripcion.precio - 100000000 } });
     //console.log("datos: ***********", respuesta.data.casa)
     //console.log("datos len: ***********", respuesta.data.casa.length)
      if(respuesta.data.casa.length > 2 ){
        GetFiltro(respuesta.data.casa)
       //console.log("datos: ***** PRIEMERA ******", respuesta.data.casa)
      }
      
      const respuestaTodas = await clienteAxios.post('/api/casas/Filtrar', {"precio": { $gt : 0}});
      const respuestaid = await clienteAxios.post('/api/casas/Filtrar', {"codigo":codigo});
      const id = {
        detalle: [
          { id: 50, nombre: 'Casa 4', precio: 80000, img: apiUrlState + respuestaid.data.casa[0].Images[0]}
        ],
        cambio: true,
        galeria: respuestaid.data.casa[0].Images,
        descripcion: respuestaid.data.casa[0]
    }
      //console.log("respuestaid: ", respuestaid.data.casa[0])
      //console.log("Detalles: ", Detalles.descripcion)
      GetidCasa(id)
      if(respuesta.data.casa.length < 3){
        GetFiltro(respuestaTodas.data.casa)
       //console.log("datos todas: ***********", respuestaTodas.data.casa)
      }
      
  
      ////console.log(error.response.data.msg); col-sm-pull-9 probar
      
  }





  useEffect(() => {
    if( headers["color"] === "#ffff" || headers["display"] === "none"){
      cambiarheader({"position": "relative", "color":"#500f0f", "display":"flex", "Img":"/img/Header-Aldia2.png", "Login":"/img/Icono-login2.png"})}
    buscar();
  }, []);
  

    return(
        
        <div className="container-fluid" style={{paddingTop: "5vh"}}>
          <div className="row">
              <div className="col-sm-5  order-12">
                <div style={{color:"#500f0f"}}>
                { (idCasa["galeria"].length > 0) ?
                  <ul className="list-group list-group-flush">
                    
                    <li className="list-group-item border-0">
                    <p style={{margin:"0px", fontSize: "30px",fontWeight: "bold"}}>{idCasa.descripcion.titulo}</p>
                    </li>
                    <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "0px", marginTop: "10px", marginLeft: "20px", marginRight: "20px"}}/>
                    <li className="list-group-item border-0">
                    <p style={{margin:"0px", fontSize: "20px",fontWeight: "bold"}}>CODIGO: {idCasa.descripcion.codigo}</p>
                    </li>

                    <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "0px", marginTop: "10px", marginLeft: "20px", marginRight: "20px"}}/>
                    <li className="list-group-item border-0">
                      <div className="form-group">
                        <div className="row align-items-center">
                          <div className="col-sm-3">
                          <p style={{margin:"0px", fontSize: "20px",fontWeight: "bold"}}>PRECIO</p> 
                          </div>
                          <div className="col-sm" style={{textAlign:"right"}}>
                            <p style={{margin:"0px", fontSize: "20px",fontWeight: "bold"}}>$ {formatNumber(idCasa.descripcion.precio)}</p> 
                          </div>
                        </div> 
                      </div>
                      </li>

                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "0px", marginTop: "10px", marginLeft: "20px", marginRight: "20px"}}/>
                      <li className="list-group-item border-0">
                        
                        <div className="row">
                          
                          
                          <div style={{width:"100px", display: "inline-flex", marginLeft:"15px"}}>
                            <img src="/img/Icono-baños.png" style={Imagenes}/>
                            <div style={{marginLeft: "10px", textAlign: "center"}}>
                              <div><label style={{margin:"0px", fontSize: "12px", fontWeight: "bold "}}>BAÑOS</label></div>
                              <label> { idCasa.descripcion.baños}</label>
                              
                            </div>
                          </div>
                          <div style={{width:"100px", display: "inline-flex", marginLeft:"15px"}}>
                            <img src="/img/Icono-garaje.png" style={Imagenes}/>
                            <div style={{marginLeft: "10px", textAlign: "center"}}>
                              <div><label style={{margin:"0px", fontSize: "12px", fontWeight: "bold "}}>GARAJE</label></div>
                              <label> { idCasa.descripcion.garaje}</label>
                              
                            </div>
                          </div>
                          <div style={{width:"100px", display: "inline-flex", marginLeft:"15px"}}>
                            <img src="/img/Icono-estrato.png" style={Imagenes}/>
                            <div style={{marginLeft: "10px", textAlign: "center"}}>
                              <div><label style={{margin:"0px", fontSize: "12px", fontWeight: "bold "}}>ESTRATO</label></div>
                              <label> { idCasa.descripcion.estrato}</label>
                              
                            </div>
                          </div>

                          <div style={{width:"140px", display: "inline-flex", marginLeft:"15px"}}>
                            <img src="/img/Icono-habitaciones.png" style={Imagenes}/>
                            <div style={{marginLeft: "10px", textAlign: "center"}}>
                              <div><label style={{margin:"0px", fontSize: "12px", fontWeight: "bold "}}>HABITACIONES</label></div>
                              <label> { idCasa.descripcion.habitaciones}</label>
                              
                            </div>
                          </div>

                          <div style={{width:"140px", display: "inline-flex", marginLeft:"15px"}}>
                            <img src="/img/longitud.png" style={Imagenes}/>
                            <div style={{marginLeft: "10px", textAlign: "center"}}>
                              <div><label style={{margin:"0px", fontSize: "12px", fontWeight: "bold "}}>FONDO</label></div>
                              <label> { idCasa.descripcion.area.ancho}m</label>
                              
                            </div>
                            <div style={{marginLeft: "10px", textAlign: "center"}}>
                              <div><label style={{margin:"0px", fontSize: "12px", fontWeight: "bold "}}>FRENTE</label></div>
                              <label> { idCasa.descripcion.area.largo}m</label>
                              
                            </div>
                          </div>

                        </div>
                      </li>

                      

                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "0px", marginTop: "10px", marginLeft: "20px", marginRight: "20px"}}/>
                      <li className="list-group-item border-0">
                          <p style={{margin:"0px", fontSize: "15px", fontWeight: "bold "}}>DESCRIPCIÓN</p>
                      </li>

                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "0px", marginTop: "10px", marginLeft: "20px", marginRight: "20px"}}/>
                      <li className="list-group-item border-0">
                      <p >{idCasa.descripcion.descripcion}</p>
                      </li>

                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "0px", marginTop: "10px", marginLeft: "20px", marginRight: "20px"}}/>
                      <li className="list-group-item border-0">
                      <form>
                        
                        <div className="form-row">
                          
                          <div style={{width:"100px", display: "inline-flex", marginLeft:"15px"}}>
                            <img src="/img/Icono-barrio.png" style={Imagenes}/>
                            <div style={{marginLeft: "10px", textAlign: "center"}}>
                              <div><label style={{margin:"0px", fontSize: "15px", fontWeight: "bold "}}>BARRIO</label></div>
                              <p style={{margin:"0px", fontSize: "15px"}}>{idCasa.descripcion.barrio}</p>
                              
                            </div>
                            <div style={{marginLeft: "10px", textAlign: "center"}}>
                              <div><label style={{margin:"0px", fontSize: "15px", fontWeight: "bold "}}>CIUDAD</label></div>
                              <p style={{margin:"0px", fontSize: "15px"}}>{idCasa.descripcion.ciudad}</p>
                              
                            </div>
                          </div> 
                        </div>
                        
                        
                      </form>
                      </li>
                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "0px", marginTop: "10px", marginLeft: "20px", marginRight: "20px"}}/>

                      <div id="accordion">
                          <div className="card" style={{ border: "0"}}>
                            <div className="card-header" id="headingOne" style={{backgroundColor: "transparent", border: "0"}}>
                             
                                <div className="row" >
                                <div className="col-7" >
                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{width:"100%", color:"#500f0f",fontSize:"20px", textAlign: "left"}}>
                                 Ver mas...
                                </button>
                                </div>
                                
                                </div>
                            </div>

                            <div id="collapseTwo" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                              <div className="card-body" style={{paddingTop: "0"}}>
                              <li className="list-group-item border-0" style={{paddingTop: "0"}}>
                      <div className="row">
                      <div className="col-sm">
                        <div className="form-group">
                        <label style={{margin:"0px", fontSize: "15px",fontWeight: "bold"}}>General</label>
                        { (idCasa.descripcion.adicional.general.conjunto ) ?

                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Conjunto" defaultChecked={idCasa.descripcion.adicional.general.conjunto} disabled/>
                          <label className="form-check-label" htmlFor="Conjunto">
                            Conjunto cerrado
                          </label>
                        </div>
                        :
                        <></>
                        }
                        { (idCasa.descripcion.adicional.general.vigilancia) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="vigilancia"  defaultChecked={idCasa.descripcion.adicional.general.vigilancia} disabled/>
                          <label className="form-check-label" htmlFor="vigilancia">
                            vigilancia
                          </label>
                        </div>
                        :
                        <></>
                        }

                        { (idCasa.descripcion.adicional.general.unifamiliar ) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Unifamiliar"  defaultChecked={idCasa.descripcion.adicional.general.unifamiliar} disabled/>
                          <label className="form-check-label" htmlFor="Unifamiliar">
                            Unifamiliar
                          </label>
                        </div>
                        :
                        
                        <></>
                        }

                        { (idCasa.descripcion.adicional.general.garaje ) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Bifamiliar"  defaultChecked={idCasa.descripcion.adicional.general.garaje} disabled/>
                          <label className="form-check-label" htmlFor="Bifamiliar">
                            Bifamiliar(Doble Renta)
                          </label>
                        </div>
                        :
                        <></>
                        }

                        { (idCasa.descripcion.adicional.general.garaje) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Garaje"  defaultChecked={idCasa.descripcion.adicional.general.garaje} disabled/>
                          <label className="form-check-label" htmlFor="Garaje">
                            Garaje
                          </label>
                        </div>
                        :
                        <></>
                        }
                        
                      {/*<div className="col-sm">
                        <div className="form-group">
                        <label > </label>*/}
                        { (idCasa.descripcion.adicional.general.citofono ) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="citofono" defaultChecked={idCasa.descripcion.adicional.general.citofono} disabled/>
                          <label className="form-check-label" htmlFor="citofono">
                            citofono
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.general.cocina) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Cocina Integral"  defaultChecked={idCasa.descripcion.adicional.general.cocina} disabled/>
                          <label className="form-check-label" htmlFor="Cocina Integral">
                            Cocina Integral
                          </label>
                        </div>
                        :
                        <></> 
                      }

                      { (idCasa.descripcion.adicional.general.bañera ) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Bañera"  defaultChecked={idCasa.descripcion.adicional.general.bañera} disabled/>
                          <label className="form-check-label" htmlFor="Bañera">
                            Bañera
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.general.patio) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Patio"  defaultChecked={idCasa.descripcion.adicional.general.patio} disabled/>
                          <label className="form-check-label" htmlFor="Patio">
                            Patio
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.general.jardin ) ? 
                        <div className="form-check">
                          <input className="form-check-input"   type="checkbox" value="" id="jardin"  defaultChecked={idCasa.descripcion.adicional.general.jardin} disabled/>
                          <label className="form-check-label" htmlFor="jardin">
                            Jardin
                          </label>
                        </div>
                        :
                        <></>
                      }
                      </div>
                      </div>
                        {/*</div>
                        </div>*/}
                        {/*<div className="col-sm">
                        
                        <div className="form-group">
                      <label > </label>*/}
                        { (idCasa.descripcion.adicional.general.piscina) ?
                        <div className="form-check">
                        
                          <input className="form-check-input"   type="checkbox" value="" id="Piscina"  defaultChecked={idCasa.descripcion.adicional.general.piscina} disabled/>
                          <label className="form-check-label" htmlFor="Piscina">
                            Piscina
                          </label>
                        </div>
                        :
                        <></>  
                        }
                        { (idCasa.descripcion.adicional.general.unaPlanta) ?
                        <div className="form-check">
                          <input className="form-check-input"   type="checkbox" value="" id="Planta"  defaultChecked={idCasa.descripcion.adicional.general.unaPlanta} disabled/>
                          <label className="form-check-label" htmlFor="Planta">
                            Una Planta
                          </label>
                        </div>
                        :
                        <></>
                        }
                        { (idCasa.descripcion.adicional.general.terraza) ?
                        <div className="form-check">
                          <input className="form-check-input"   type="checkbox" value="" id="Terraza"  defaultChecked={idCasa.descripcion.adicional.general.terraza} disabled/>
                          <label className="form-check-label" htmlFor="Terraza">
                            Terraza
                          </label>
                        </div>
                        :
                        <></>
                        }  
                        {/*</div>
                      </div>*/}
                      {/*<div  style={{borderLeft: "2px solid #500f0f", textAlign: "initial"}}>*/}
                      <div className="col-sm" >
                        <div className="form-group">
                        <label style={{margin:"0px", fontSize: "15px",fontWeight: "bold"}}>Zonas Comunes</label>
                        { (idCasa.descripcion.adicional.comunes.piscina ) ?
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="PiscinaC" defaultChecked={idCasa.descripcion.adicional.comunes.piscina} disabled/>
                          <label className="form-check-label" htmlFor="PiscinaC">
                            Piscina Comunal
                          </label>
                        </div>
                        :
                        <></>
                        }

                      { (idCasa.descripcion.adicional.comunes.canchas ) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Canchas"  defaultChecked={idCasa.descripcion.adicional.comunes.canchas} disabled/>
                          <label className="form-check-label" htmlFor="Canchas">
                            Canchas Deportivas
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.comunes.zverde) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="ZonasV"  defaultChecked={idCasa.descripcion.adicional.comunes.zverde} disabled/>
                          <label className="form-check-label" htmlFor="ZonasV">
                            Zonas Verdes
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.comunes.zinfantil) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="ZonaI"  defaultChecked={idCasa.descripcion.adicional.comunes.zinfantil} disabled/>
                          <label className="form-check-label" htmlFor="ZonaI">
                            Zona Infantil
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.comunes.zpet ) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="ZonaP"  defaultChecked={idCasa.descripcion.adicional.comunes.zpet} disabled/>
                          <label className="form-check-label" htmlFor="ZonaP">
                            Zona P
                          </label>
                        </div>
                        :
                        <></>
                      }
                        </div>
                      </div>
                      
                      
                      <div className="col-sm">
                        <div className="form-group">
                        <label style={{margin:"0px", fontSize: "15px",fontWeight: "bold"}}>Del Sector</label>
                        { (idCasa.descripcion.adicional.sector.supermercados) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="supermercados" defaultChecked={idCasa.descripcion.adicional.sector.supermercados} disabled/>
                          <label className="form-check-label" htmlFor="supermercados">
                            Cerca a Supermercados
                          </label>
                        </div>
                        :
                        <></>
                        }

                      { (idCasa.descripcion.adicional.sector.comercial) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Colegios"  defaultChecked={idCasa.descripcion.adicional.sector.comercial} disabled/>
                          <label className="form-check-label" htmlFor="Colegios">
                           Cerca a Colegios / Universidades
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.sector.transporte ) ?
                        <div className="form-check">
                          <input className="form-check-input"  type="checkbox" value="" id="Transporte"  defaultChecked={idCasa.descripcion.adicional.sector.transporte} disabled/>
                          <label className="form-check-label" htmlFor="Transporte">
                            Cerca Transporte Publico
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.sector.parque ) ?
                        <div className="form-check">
                          <input className="form-check-input "  type="checkbox" value="" id="Parques"  defaultChecked={idCasa.descripcion.adicional.sector.parque} disabled/>
                          <label className="form-check-label" htmlFor="Parques">
                            Cerca a Parques
                          </label>
                        </div>
                        :
                        <></>
                      }

                      { (idCasa.descripcion.adicional.sector.comercial ) ?
                        <div className="form-check">
                          <input className="form-check-input "  type="checkbox" value="" id="Restaurantes"  defaultChecked={idCasa.descripcion.adicional.sector.comercial} disabled/>
                          <label className="form-check-label" htmlFor="Restaurantes">
                            Cerca a Restaurantes
                          </label>
                        </div>
                        :
                        <></>
                      }
                        </div>
                      </div>
                      
                      </div>
                      </li>
                              </div>
                            </div>
                          </div>
                        </div>
                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "0px", marginTop: "10px", marginLeft: "20px", marginRight: "20px"}}/>
                    </ul>
                    :
                    <></>
                    }

                  </div>
              </div>
              <div className="col-sm-7">
              <div className="container" style={{height:"700px"}}>
                <CarouselDetalle images={images}/>
                {/*<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner ">
                        
                    {Detalles["galeria"].map((imagen, index) => (
                    //console.log("Map Get Casas: ", imagen)
                          <Carrousel 
                                key = {imagen}
                                Detalles={Detalles}
                                imagen={imagen}
                                apiUrlState={apiUrlState}
                                Estado={index}
                          />
                        ))}  
                        
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                    </div>*/}
                  </div>
              </div>
              
          </div>
          
          
            {/*<div className="col-sm-3" style={{borderLeft: "3px solid #500f0f", textAlign: "center"}}>*/}
            <div className="container-fluid" style={{marginBottom: "50px", marginTop:"50px"}}>
            <div><h4 className="card-title" style={{margin:"20px", textAlign: "center",fontSize: "40px",fontWeight: "bold", color:"#4c0000"}}>Inmuebles relacionados</h4></div>
            { (Filtro.length > 0) ?
            <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-4">
                <a href={"/Detalle/"+idCasa.descripcion.codigo} onClick ={() =>Detallescasa(Filtro[0], 0)}>
                <div className="card" style={{}}>
                  <img className="card-img-top" src={apiUrlState + Filtro[0].Images[0]} alt="Card image cap" style={{ maxHeight: "400px",objectFit: "cover"}}/>
                  <div className="card-body" style={{textAlign: "center"}}>
                    <h5 className="card-title"  style={{color: "#500f0f"}}>$ {formatNumber(Filtro[0].precio)}</h5>
                    <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "10px", marginTop: "10px", marginLeft: "0px", marginRight: "0px"}}/>
                      <p className="card-text" style={{color: "#500f0f", fontSize: "15px"}}>Ciudad: {Filtro[0].ciudad} </p>
                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "10px", marginTop: "10px", marginLeft: "0px", marginRight: "0px"}}/>
                      <p className="card-text" style={{color: "#500f0f", fontSize: "15px"}}>{Filtro[0].titulo} </p>
                    <div onClick ={() =>Detallescasa(Filtro[0], 0)}>
                    
                          <div  className="btn btn-primary" style={{backgroundColor:"#4c0000", border:"0"}}>Ver Detalles</div>
                   
                    </div>
                  </div>
                </div>
                </a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                <a href={"/Detalle/"+idCasa.descripcion.codigo} onClick ={() =>Detallescasa(Filtro[1], 1)}>
                  <div className="card" style={{ }}>
                    <img className="card-img-top" src={apiUrlState + Filtro[1].Images[0]} alt="Card image cap" style={{ maxHeight: "400px",objectFit: "cover"}}/>
                    <div className="card-body" style={{textAlign: "center"}}>

                      <h5 className="card-title" style={{color: "#500f0f"}}>$ {formatNumber(Filtro[1].precio)}</h5>
                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "10px", marginTop: "10px", marginLeft: "0px", marginRight: "0px"}}/>
                      <p className="card-text" style={{color: "#500f0f", fontSize: "15px"}}>Ciudad: {Filtro[1].ciudad} </p>
                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "10px", marginTop: "10px", marginLeft: "0px", marginRight: "0px"}}/>
                      <p className="card-text" style={{color: "#500f0f", fontSize: "15px"}}>{Filtro[1].titulo} </p>
                      <div onClick ={() =>Detallescasa(Filtro[1], 1)}>
                      
                        <button href="#header" className="btn btn-primary" style={{backgroundColor:"#4c0000", border:"0"}}>Ver Detalles</button>
                      
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                <a href={"/Detalle/"+idCasa.descripcion.codigo} onClick ={() =>Detallescasa(Filtro[2], 2)}>
                  <div className="card" style={{}}>
                    <img className="card-img-top" src={apiUrlState + Filtro[2].Images[0]} alt="Card image cap" style={{ maxHeight: "400px",objectFit: "cover"}}/>
                    <div className="card-body" style={{textAlign: "center"}}>
                      <h5 className="card-title" style={{color: "#500f0f"}}>$ {formatNumber(Filtro[2].precio)}</h5>
                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "10px", marginTop: "10px", marginLeft: "0px", marginRight: "0px"}}/>
                      <p className="card-text" style={{color: "#500f0f", fontSize: "15px"}}>Ciudad: {Filtro[2].ciudad} </p>
                      <hr style={{ color: '#500f0f', backgroundColor: '#500f0f', height: 1, borderColor : '#500f0f', marginBottom: "10px", marginTop: "10px", marginLeft: "0px", marginRight: "0px"}}/>
                      <p className="card-text" style={{color: "#500f0f", fontSize: "15px"}}>{Filtro[2].titulo} </p>
                      <div onClick ={() =>Detallescasa(Filtro[2], 2)}>
                      
                        <button href="#" className="btn btn-primary" style={{backgroundColor:"#4c0000", border:"0"}}>Ver Detalles</button>
                        
                      
                      </div>
                    </div>
                  </div>
                  </a>  
                </div>

                </div>
                :
                
                <></>
               
              
            }
            
          </div>
      </div>
 
            
        
        
    )


};
    
        

export default Detalle;

