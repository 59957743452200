import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import TagManager from "react-gtm-module";
const Home = ({ headers, cambiarheader }) => {
  useEffect(() => {
    if (headers["color"] === "#500f0f" || headers["display"] === "none")
      cambiarheader({
        position: "absolute",
        color: "#ffff",
        display: "flex",
        Img: "/img/Header-Aldia.png",
        Login: "/img/Icono-login.png",
      });
  });

  const back = {
    backgroundImage: "url('/img/aldia-fondo.jpg')",
  };
  const absolute = { position: "absolute", nombre: "Casa 4", precio: 80000 };

  const analytics = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "BusquedaCasas",
        path: "/Inmuebles/Casas",
      },
    });
  };

  return (
    <div
      className="Titulo"
      style={{ backgroundImage: "url('/img/aldia-fondo.jpg')" }}
    >
      <div className="row" style={{ width: "100%" }}>
        <div
          className="col-lg-12 col-centered"
          style={{ padding: "0", textAlign: "center" }}
        >
          <p className="Text">Tu hogar ideal</p>
          <Link to="/Inmuebles/Casas">
            <button className="btn btn-outline-light text-center botones ">
              Encuentra tu casa
            </button>
          </Link>
          <Link to="/Inmuebles/Lotes">
            <button
              href="/Inmuebles/Lotes"
              className="btn btn-outline-light text-center botones "
            >
              Encuentra tu lote
            </button>
          </Link>
          <Link to="/Contacto" onClick={() => analytics()}>
            <button className="btn btn-outline-light text-center botones2">
              Vende tu casa
            </button>
          </Link>
          <a
            className="btn btn-outline-light text-center botones2"
            href="https://https://linktr.ee/aldia.inmobiliaria"
            target="_blank"
          >
            Contactar{" "}
            <img className="imagesRedes2" src="/img/Icono-whatsapp.png" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
