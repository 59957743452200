import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = ({ fecha }) => {
  return (
    <div
      className="style  "
      style={{ backgroundColor: "#500f0f", position: "relative", bottom: "0" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 col-sm-4  col-md-4 col-lg-3 align-self-center visible"
            style={{ color: "white", textAlign: "center" }}
          >
            <a
              className="navbar-brand"
              href="/"
              style={{ color: "white", fontSize: "55px" }}
            >
              <img src="/img/Footer-Aldia.png" />
            </a>
          </div>
          <div
            className="col-10 col-sm-6 col-md-4 col-lg-3"
            style={{ color: "white", paddingTop: "3px" }}
          >
            <ul className="list-group list-group-flush">
              {/*<li className="list-group-item  border-0 CintactoFooter" style={{backgroundColor: "transparent",  fontWeight: "bold", fontSize: "20px", padding: "0"}}>Contacto</li>*/}
              <li className="list-group-item  border-0 contacto">
                <img className="images" src="/img/icono-Dirección.png" /> Tuluá
                valle del cauca
              </li>
              {/* <li className="list-group-item  border-0 contacto">
                <img className="images" src="/img/Icono-telefono.png" /> +57 315
                4866546
              </li>
              <li className="list-group-item  border-0 contacto">
                <img
                  className="images"
                  src="/img/Icono-telefono.png"
                  style={{ color: "transparent", visibility: "hidden" }}
                />
                +57 322 9764882
              </li>
              <li className="list-group-item  border-0 contacto">
                <img
                  className="images"
                  src="/img/Icono-telefono.png"
                  style={{ color: "transparent", visibility: "hidden" }}
                />
                +57 3174231366
              </li> */}
              {/*<li className="list-group-item  border-0" style={{backgroundColor: "transparent",paddingRight: "0",paddingLeft: "0"}}><img src="/img/Icono-telefono.png" /> 230021024</li>*/}
              <li className="list-group-item  border-0 contacto">
                <img className="images" src="/img/Icono-email.png" />{" "}
                aldia.inmobiliaria@hotmail.com
              </li>
              {/*<li className="list-group-item  border-0" style={{backgroundColor: "transparent"}}>Vestibulum at eros</li>*/}
            </ul>
          </div>
          <div
            className=" col-sm-4  col-md-4 col-lg-3 visible"
            style={{ color: "white" }}
          >
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item  border-0"
                style={{
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Enlaces
              </li>
              <Link to="/">
                <li
                  className="list-group-item  border-0"
                  style={{ backgroundColor: "transparent", color: "white" }}
                >
                  {" "}
                  Inicio
                </li>
              </Link>
              <Link to="/Inmuebles">
                <li
                  href="/Inmuebles"
                  className="list-group-item  border-0"
                  style={{ backgroundColor: "transparent", color: "white" }}
                >
                  Inmuebles
                </li>
              </Link>
              <Link to="/Nosotros">
                <li
                  className="list-group-item  border-0"
                  style={{ backgroundColor: "transparent", color: "white" }}
                >
                  Sobre Nosotros
                </li>
              </Link>
              <Link to="/Contacto">
                <li
                  className="list-group-item  border-0"
                  style={{ backgroundColor: "transparent", color: "white" }}
                >
                  Contáctanos
                </li>
              </Link>
            </ul>
          </div>
          <div
            className="col-2 col-sm-4  col-md-4 col-lg-3 align-self-center"
            style={{ color: "white" }}
          >
            <div className="row" style={{ textAlign: "center" }}>
              <div
                className="col-12 col-sm-4  col-md-4 col-lg-4"
                style={{ paddingTop: "5px" }}
              >
                <a
                  href="https://www.facebook.com/Aldia-Inmobiliaria-103148328547871/"
                  target="_blank"
                >
                  <img className="imagesRedes" src="/img/icono-facebook.png" />
                </a>
              </div>
              <div
                className="col-12 col-sm-4  col-md-4 col-lg-4"
                style={{ paddingTop: "5px" }}
              >
                <a href="https://linktr.ee/aldia.inmobiliaria" target="_blank">
                  <img className="imagesRedes" src="/img/Icono-whatsapp.png" />
                </a>
              </div>
              <div
                className="col-12 col-sm-4  col-md-4 col-lg-4"
                style={{ paddingTop: "5px" }}
              >
                <a
                  href="https://www.instagram.com/aldia_inmobiliaria/"
                  target="_blank"
                >
                  <img className="imagesRedes" src="/img/icono-instagram.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
